// AirPremia Color Preset...
$ap-solid-orange  : #ff4713;
$ap-solid-darkblue: #0C2340;
$ap-solid-red     : #ED0000;
$ap-solid-red-dark : #a9002f;
$ap-solid-skyblue : #0093FF;
$ap-solid-orange-dark: #e64500;
$ap-solid-skyblue-dark : #0285e5;

$ap-point-orange  : #FF5800;
$ap-point-skin    : #FFAC88;
$ap-point-skin-light    :#fff6f4;
$ap-point-blue    : #0093FF;
$ap-point-blue-dark : #324158;
$ap-point-sky     : #7FD3F8;
$ap-point-skyblue : #d4effd;
$ap-point-skyblue-light : #f2f9ff;
$ap-point-info    : #8ecfff;
$ap-point-info-light : #f9fafc;

$ap-text-light    : #E5E5E5;
$ap-text-black    : #222222;
$ap-text-dark     : #343434;
$ap-text-gray-0   : #333333;
$ap-text-gray-1   : #808080;
$ap-text-gray-2   : #B2B2B2;
$ap-text-gray-3   : #CCCCCC;
$ap-text-gray-4   : #DDDDDD;
$ap-text-gray-5   : #fafafa;
$ap-text-gray-6   : #F7F7F7;
$ap-text-gray-7   : #F4F6F8;
$ap-text-link-gnb : #333D4B;

$ap-mobile-bg     : #6b7586;

$ap-line-black    : #222222;
$ap-line-gray     : #E5E5E5;
$ap-line-gray-light : #eeeeee;
$ap-line-light    : #F2F2F2;

$ap-white         : #ffffff;
$ap-red           : #ff0000;
$ap-black         : #000000;
$ap-wild          : #FFF7E2;

$ap-beige-1       : #ffceb4;
$ap-beige-2       : #fff6f4;
$ap-class-1       : #f3eeea;
$ap-class-2       : #FBF8F5;
$ap-modern-1      : #A2AABA;
$ap-modern-2      : #18263e;
$ap-modern-3      : #6D7788;


$ap-rose-1        : #b7312b;
$ap-brown-1       : #c1b8af;
$ap-gold-1        : #f5d682;
$ap-green-1       : #00ce70;

$ap-lotte-1     : #fce0e0;
$ap-shilla-1    : #fef7da;
$ap-hyundai-1   : #ffd3be;

$h1: (
        'xs': 1.375rem,
        'sm': 1.375rem,
        'md': 1.375rem,
        'lg': 1.5rem,
        'xl': 2rem,
        'ul': 2rem
) !default;
$h2: (
        'xs': 1.125rem,
        'sm': 1.125rem,
        'md': 1.125rem,
        'lg': 1.25rem,
        'xl': 1.75rem,
        'ul': 1.75rem
) !default;
$h3: (
        'xs': 1rem,
        'sm': 1rem,
        'md': 1rem,
        'lg': 1.125rem,
        'xl': 1.5rem,
        'ul': 1.5rem
) !default;
$h4: (
        'xs': 0.875rem,
        'sm': 0.875rem,
        'md': 0.875rem,
        'lg': 1rem,
        'xl': 1.25rem,
        'ul': 1.25rem
) !default;
$h5: (
        'xs': 0.813rem,
        'sm': 0.813rem,
        'md': 0.813rem,
        'lg': 0.938rem,
        'xl': 1.125rem,
        'ul': 1.125rem
) !default;
$h6: (
        'xs': 0.75rem,
        'sm': 0.75rem,
        'md': 0.75rem,
        'lg': 0.875rem,
        'xl': 1rem,
        'ul': 1rem
) !default;
$p: (
        'xs': 0.75rem,
        'sm': 0.75rem,
        'md': 0.75rem,
        'lg': 0.813rem,
        'xl': 0.938rem,
        'ul': 0.938rem
) !default;


// Response Web Preset...
$breakpoints: (
        'xs': 410px,
        'sm': 767px,
        'md': 1023px,
        'lg': 1279px,
        'xl': 1439px,
        '2xl': 1679px,
        'ul': 1919px,
) !default;

@mixin rwd($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@function toRem($value){
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}
