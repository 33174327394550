@charset "UTF-8";
@import "ap_preaset";


/* ----- sub ----- */
.layout {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 60px 0 200px;
  max-width: 1340px;

  @include rwd('ul') {
    max-width: 1340px;
    padding: 60px 0 200px;
  }
  @include rwd('2xl') {
    max-width: 1340px;
    padding: 60px 0 200px;
  }
  @include rwd('xl') {
    max-width: 1280px;
    padding: 60px 40px 200px;
  }
  @include rwd('lg') {
    //max-width: 1024px;
    padding: 60px 40px 140px;
  }
  @include rwd('md') {
    padding: 20px 16px 100px;
  }
}

.layout.fltPoint {
  padding: toRem(60) toRem(40) toRem(200);
}

.layout.frLayout.ssrPage {
  max-width: toRem(1280);
}

.layout.chkPage {
  max-width: toRem(1200);

  @include rwd('xl') {
    max-width: 1280px;
    padding: 60px 40px 200px;
  }
  @include rwd('lg') {
    padding: 60px 40px 140px;
  }
  @include rwd('md') {
    padding: 48px 16px 100px;
  }
}

.comTitle {
  margin-bottom: toRem(48);
}

.comTitle.mt100 {
  margin-top: toRem(100);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}
.flex.haveStep {
  gap: toRem(24);
}

.hidden {
  display: none;
}
.pb-24 {
  padding-bottom: toRem(24);
}

.gap-1{
  gap: toRem(4);
}
.gap-2{
  gap: toRem(8);
}
.gap-3{
  gap: toRem(12);
}

.subTit {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 150% */
  word-break: break-word;
}

.subTit .sub {
  margin-top: toRem(20);
  font-size: toRem(16);
  line-height: 1.8em;
}

.subMdTit {
  font-size: toRem(26);
}

.subSmTit {
  font-size: toRem(20);
  line-height: toRem(30);
}

.comStyle {
  border-top: toRem(2) solid $ap-line-black;
}

.subXSmTit {
  font-size: toRem(18);
  font-weight: 500;
}

.comContent {
  padding-top: toRem(120);

  &.bdT {
    border-top: toRem(2) solid $ap-line-black;
  }

  &.pd0 {
    padding-top: 0;
  }

  &.pd10 {
    padding-top: toRem(10);
  }

  &.pd20 {
    padding-top: toRem(20);
  }

  &.pd30 {
    padding-top: toRem(30);
  }

  &.pd60 {
    padding-top: toRem(60);
  }

  &.bdB {
    padding-bottom: toRem(60);
    border-bottom: toRem(2) solid $ap-line-black;
  }

  .contents {
    margin-top: toRem(60);

    &:first-of-type {
      margin-top: toRem(20);
    }
  }
}

/* Hyundai Insurance Banner */
.hyundaiBanner,
.hyundaiBanner .pc,
.hyundaiBanner .mobile {
  width: 100%;
}

.hyundaiBanner .mobile {
  display: none;
}

.hyundaiBanner .background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: toRem(160);
  border-radius: toRem(16);
  margin-top: toRem(64);
  padding: toRem(24);
  background-color: #F9F9FB;
}

.hyundaiBanner .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: toRem(720);
}

.hyundaiBanner .container .title {
  font-size: toRem(18);
  font-weight: 700;
  color: #171A22;
  margin-bottom: toRem(8);
}

.hyundaiBanner .container .desc {
  font-size: toRem(13);
  color: #333D4B;
  margin-bottom: toRem(8);
}

.hyundaiBanner .container .law {
  font-size: toRem(8);
  color: #BFC4D0;
}

.hyundaiLogo {
  width: 100%;
  max-width: 102px;
}

@include rwd('md') {
  .hyundaiBanner .pc {
    display: none;
  }
  .hyundaiBanner .mobile {
    display: block;
  }
  .hyundaiBanner .background {
    margin-top: toRem(48);
  }
}

.buttonWrap {
  margin-top: toRem(80);
}


/* li 공통 */
.listGlobal > li {
  padding-left: toRem(10);
  position: relative;
  line-height: 1.6em;
}

.listGlobal > li::before {
  content: '';
  width: toRem(3);
  height: toRem(3);
  display: block;
  position: absolute;
  top: toRem(10);
  left: 0;
  border-radius: 50%;
  background: $ap-text-black;
}

.listGlobal > li.none::before {
  content: none;
}

.listGlobal > li.gray {
  color: $ap-text-gray-1;
}

.listGlobal > li.gray.star,
.listGlobal > li.star.ftG,
.listGlobal > li.ball.gray {
  font-size: toRem(14);
}

.listGlobal > li.star::before {
  content: '*';
  top: 0;
  background: transparent;
}

.listGlobal > li.star.oran::before {
  color: $ap-point-orange;
}

.listGlobal > li.ball {
  padding-left: toRem(20);
}

.listGlobal > li.ball::before {
  content: '※';
  top: 0;
  background: transparent;
}

.listGlobal > li.ball.ftG::before {
  color: $ap-text-gray-1;
}

.listGlobal > li.scd {
  margin-top: 0;
  padding-left: toRem(24);
  margin-bottom: 0;
}

.listGlobal > li.scd::before {
  content: "-";
  top: toRem(-2);
  left: toRem(14);
  background: none;
}

.listGlobal > li.scd.none::before {
  content: none;
}

.listGlobal > li.trd {
  margin-top: 0;
  padding-left: toRem(40);
  color: $ap-text-gray-1;
  font-weight: 300;
}

.listGlobal > li.trd::before {
  left: toRem(30);
  background-color: $ap-text-gray-1;
}

.listGlobal > li.four {
  margin-top: 0;
  padding-left: toRem(54);
  color: $ap-text-gray-1;
  font-weight: 300;
}

.listGlobal > li.four::before {
  content: "-";
  top: toRem(-2);
  left: toRem(44);
  background: none;
}

.listGlobal.ex {
  padding-left: toRem(30);
  position: relative;
}

.listGlobal.ex:before {
  content: 'EX)';
  position: absolute;
  top: 0;
  left: 0;
}

.listGlobal.ex > li {
  padding-left: 0;
  margin-bottom: 0;
}

.listGlobal.ex > li:before {
  display: none;
}

.listGlobal.txtLft {
  text-align: left;
}

ol.listGlobal {
  margin-top: toRem(15);
  padding-left: toRem(30);
}

ol.listGlobal > li {
  padding: 0;
  margin-bottom: 0;
  list-style: decimal;
}

ol.listGlobal > li::before {
  display: none;
}

ol.listGlobal.lh > li {
  margin-top: toRem(10);
}

.nmS > li {
  padding-left: toRem(20);
  position: relative;
}

.nmS.n2 > li {
  padding-left: toRem(30);
  position: relative;
}

.nmS li > span {
  position: absolute;
  top: 0;
  left: 0;
}

.nmS li > span.fwB {
  position: relative;
  top: 0;
  left: 0;
}

.nmS.txtKr > li {
  padding-left: toRem(25);
}

ol.txtKr {
  padding-left: toRem(25);
}

ol.txtKr > li {
  padding-left: toRem(5);
  margin-bottom: toRem(5);
  list-style: upper-alpha;
}

.nmb {
  margin-bottom: toRem(15);
}

.nmb:last-child {
  margin-bottom: 0;
}

.nmb dt {
  margin-bottom: toRem(15);
  font-size: toRem(18);
  font-weight: 500;
}

.nmb dd {
  line-height: toRem(16);
  letter-spacing: toRem(-0.3);
}

ol.premCount {
  margin-top: toRem(20);
  counter-reset: item;
  list-style-type: none;
}

ol.premCount li {
  margin-top: toRem(20);
  display: flex;
}

ol.premWhy li {
  margin-top: toRem(20);
  padding-left: toRem(25);
  display: block;
  position: relative;
  align-items: center;
}

ol.premCount li:first-child {
  margin-top: 0;
}

ol.premCount li .number {
  margin-right: toRem(10);
  color: $ap-solid-orange;
  font-size: toRem(20);
  font-weight: 700;
}

@include rwd('sm') {
  .layout {
    padding: toRem(32) toRem(25) toRem(200);
  }
  .layout.frLayout {
    padding-bottom: toRem(300);
  }
  .contWrapper .left .panels {
    padding-top: toRem(12);
  }
  .comTitle {
    margin-bottom: toRem(24);
  }
  .subTit {
    font-size: toRem(26);
    line-height: 40px;
  }
  .subTit .sub {
    margin-top: toRem(10);
    font-size: toRem(14);
  }
  .subMdTit {
    font-size: toRem(18);
  }
  .subSmTit {
    font-size: toRem(16);
  }
  .subXSmTit {
    font-size: toRem(14);
  }
  .comContent {
    padding-top: toRem(60);
  }
  .flex.haveStep {
    flex-direction: column-reverse;
    align-items: baseline;
  }
  .listGlobal > li {
    font-size: toRem(14);
    line-height: 1.6em;
  }
  .listGlobal > li.scd {
    padding-left: toRem(20);
  }
  .listGlobal > li.trd {
    padding-left: toRem(33);
  }
  .listGlobal > li.four {
    padding-left: toRem(45);
  }
  .listGlobal > li::before {
    top: toRem(10);
  }
  .listGlobal > li.scd::before {
    left: toRem(12);
  }
  .listGlobal > li.trd::before {
    left: toRem(23);
  }
  .listGlobal > li.four::before {
    left: toRem(35);
  }
  .comContent .contents.memb {
    margin-top: toRem(20);
  }
  .nmb dt {
    font-size: toRem(16);
  }
  .nmb dd {
    font-size: toRem(14);
  }
  .listGlobal > li.gray.star,
  .listGlobal > li.star.ftG,
  .listGlobal > li.ball.gray {
    font-size: toRem(13);
  }
}

/****************
***** header ****
****************/

.headerGroup {
  width: 100%;
  top: 0;
  background: $ap-white;
  z-index: 110;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: toRem(14);
  height: toRem(60);
}

.covidNtc {
  background-color: $ap-line-light;
}

.billboard a:hover .txt {
  text-decoration: underline;
}

.billx .icon.close {
  width: 1.5rem;
  height: 1.5rem;
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_close_gray.svg) no-repeat;
}

/* gnb */
@import "inc/gnb";

/* 개인정보 처리방침 */
.psInfo .comContent .taRight {
  padding-top: toRem(20);
  text-align: right;
}

@include rwd('sm') {
  .psInfo .comContent.pd30 {
    padding-top: toRem(20);
  }
  .psInfo ol.txtKr > li {
    margin-bottom: 0;
  }
  .psInfo .listGlobal.pdL20 {
    margin-top: 0;
    padding-left: toRem(20);
  }
  .psInfo .listGlobal .br {
    display: block;
  }
}


/* 홈페이지 이용약관 */
.hpTerms ol.listGlobal.pdL20 {
  margin-top: 0;
  padding-left: toRem(20);
}

@include rwd('sm') {
  .hpTerms .comContent.pd30 {
    padding-top: toRem(20);
  }
}


/* 국제여객운송약관 */
.trnsTerms ol.listGlobal.pdL30 {
  margin-top: 0;
  padding-left: toRem(20);
}

.trnsTerms ol.txtKr > li {
  padding-left: 0;
}

@include rwd('sm') {
  .trnsTerms .comContent.pd30 {
    padding-top: toRem(20);
  }
}


/* 국제화물운송약관 */
.itnTerms .comContent.pd30 {
  padding-top: toRem(20);
}


@include rwd('sm') {
  .itnTerms .comContent.pd60 {
    padding-top: toRem(40);
  }
}

/*************************
******** webview ********
*************************/

body[data-is-webview="true"] {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  input,
  textarea {
    user-select: auto;
  }

  .webviewHidden {
    display: none;
  }
}

.webviewHeader {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  background-color: #ffffff;
  padding: 0.5rem 1rem;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftButton,
    .rightButton {
      display: flex;
      flex-shrink: 0;
      height: 1.5rem;
      width: 1.5rem;

      button {
        display: block;
        color: rgba(51, 61, 75, 0.94);
      }
    }

    .title {
      height: 1.75rem;
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.75rem;
      color: #171A22;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

/****************
***** main *****
****************/


/* mainNotice */
.mainNotice {
  width: 100%;
  height: toRem(70);
  display: table;
  overflow: hidden;
  text-align: center;
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/home/MainNotice_bg.jpg') no-repeat;
  background-size: cover;
}

.mainNotice .txt {
  padding: 0 toRem(25);
  display: table-cell;
  color: $ap-white;
  line-height: 1.4em;
  vertical-align: middle;
}

.mainNotice .txt:hover {
  cursor: pointer;
}


/* darksite */
.mainDarkSiteNotice {
  width: 100%;
  padding: toRem(30) toRem(25) toRem(50);
  position: relative;
  text-align: center;
  background: $ap-text-black;
  cursor: pointer;
}

.mainDarkSiteNotice .arrow {
  margin-right: toRem(-10);
  position: absolute;
  bottom: toRem(20);
  right: 50%;
}

.mainDarkSiteNotice .txt {
  color: $ap-white;
  font-size: 1.8rem;
  line-height: 1.6em;
}

.mainDarkSiteNotice .DarkSiteTag,
.mainDarkSiteNotice .DarkSiteContent,
.mainDarkSiteNotice .DarkSiteContactInfo {
  display: none;
  animation-name: opacity;
  animation-duration: 1.5s;
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mainDarkSiteNotice .DarkSiteTag {
  padding: toRem(4) toRem(10);
  margin-bottom: toRem(30);
  background: $ap-point-orange;
  border-radius: toRem(12);
  font-family: 'Premia', sans-serif;
  line-height: 1em;
  text-transform: uppercase;
}

.mainDarkSiteNotice.on {
  padding: toRem(80) toRem(25);
  transition: linear 0.7s;
}

.mainDarkSiteNotice.on .arrow {
  transform: rotate(180deg);
}

.mainDarkSiteNotice.on .DarkSiteTag {
  display: inline-block;
}

.mainDarkSiteNotice.on .DarkSiteContactInfo,
.mainDarkSiteNotice.on .DarkSiteContent {
  display: block;
}

.mainDarkSiteNotice.on .DarkSiteTitle {
  margin-bottom: toRem(30);
  font-size: toRem(32);
  font-weight: 700;
  animation-name: opacity;
  animation-duration: 1.5s;
}

/* mainWrap */
.mainWrap {
  padding-bottom: 100px;
  position: relative;
}


/* heroWrap */
.heroWrap {
  margin-bottom: toRem(64);
}

.heroWrap .hero {
  display: flex;
  justify-content: space-between;
}

.heroWrap .homeTicket {
  width: toRem(460);
  min-width: toRem(336);
  height: toRem(560);
  position: relative;
}

.heroWrap .homeTicket .angle {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.heroWrap .homeTicket .angle span {
  width: toRem(30);
  height: toRem(34);
  position: absolute;
  display: grid;
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/angle_a.svg') no-repeat;
}

.heroWrap .homeTicket .angle span.left {
  left: toRem(-30);
}

.heroWrap .homeTicket .angle span.right {
  right: toRem(-30);
}

.heroWrap .homeTicket .angle span.bottom {
  bottom: toRem(0);
}

.heroWrap .homeTicket .angle span.x-rotate {
  transform: scaleX(-1);
}

.heroWrap .homeTicket .angle span.y-rotate {
  transform: scaleY(-1);
}

.heroWrap .homeTicket .angle span.xy-rotate {
  transform: scale(-1);
}


/* vertical */
.heroWrap .homeTicket .vertical {
  height: 100%;
  padding: toRem(24) toRem(48) toRem(18) toRem(48);
  //padding:toRem(32);
  background-color: $ap-white;
}

.fltConVtc {
  width: 100%;
  user-select: none;
  background-color: $ap-white;
}

.fltConVtc .imgVtcBg {
  position: absolute;
  top: toRem(25);
  right: toRem(25);
}

.fltConVtc .imgVtcBg img {
  width: toRem(80);
}

.fltConVtc h3 {
  margin-bottom: toRem(56);
  color: $ap-text-black;
  font-size: toRem(30);
  font-weight: 700;
  line-height: 1.4em;
}

.fltConVtc .middle .slctCon {
  left: toRem(390);
}

.fltConVtc .middle .station .slctCon {
  width: toRem(400);
  top: 0;
}

.fltConVtc .bottom {
  margin-top: toRem(40);
  font-weight: 700;
}

.fltConVtc .bottom .taskButton {
  overflow: hidden;
  border-radius: toRem(64);
}

.fltConVtc .bottom .taskButton .iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: toRem(16);
}

.fltConVtc .bottom .taskButton .iconWrap .icon {
  margin-left: toRem(8);
  width: toRem(20);
  height: toRem(20);
  background-size: toRem(20);
}


/* sectionWrapper */
.sectionWrapper {
  margin-bottom: toRem(56);
}

.sectionWrapper:last-child {
  margin-bottom: 0;
}

.sectionWrapper .titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sectionWrapper .titleWrap .title h3 {
  color: $ap-solid-orange;
  font-weight: 700;
  font-size: toRem(32);
}

.sectionWrapper .titleWrap .more:hover .txt {
  text-decoration: underline;
}

.sectionWrapper .cont {
  margin-top: toRem(10);
}


/* mainSecEvent */
.mainSecEvent ul {
  display: flex;
  justify-content: space-between;
  gap: toRem(20);
}

.mainSecEvent .list {
  //width: calc(25% - #{toRem(15)}); //spacing 값이 입력값과 상이하게 나옴 gap으로 변경
  width: 25%;
}

.mainSecEvent .list .badgeWrap {
  margin-top: toRem(20);
}

.mainSecEvent .list h4 {
  margin-top: toRem(8);
  font-weight: 500;
  line-height: 1.6em;
}

.mainSecEvent .list:hover h4 {
  color: $ap-solid-orange;
}

.mainSecEvent .list .date {
  margin-top: toRem(4);
}

.mainSecEvent .list a {
  display: block;
}

.mainSecEvent .imgRound {
  aspect-ratio: 379 / 193;
}

.mainSecEvent .imgRound img {
  height: 100%;
  @include rwd('sm') {
    max-height: 22.5vw;
    min-height: 70px;
  }
}

/* mainSecNotice */
.mainSecNotice {
  border-top: toRem(1) solid $ap-line-black;
  border-bottom: toRem(1) solid $ap-line-black;
}

.mainSecNotice.qust {
  border-top: 0;
}

.mainSecNotice li {
  padding: toRem(20) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: toRem(1) solid $ap-line-gray;
  cursor: pointer;
}

.mainSecNotice li:last-child {
  border-bottom: 0;
}

.mainSecNotice a {
  width: 100%;
}

.mainSecNotice .title {
  width: calc(100% - #{toRem(120)});
}

.mainSecNotice h4 {
  font-weight: 500;
}

.mainSecNotice li:hover h4 {
  font-size: toRem(16);
  color: $ap-solid-orange;
}

.mainSecNotice .desc {
  display: block;
  font-size: toRem(15);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mainSecNotice .date {
  float: right;
  font-size: toRem(14);
}


/* bg */
.mainWrap .bg {
  width: 100%;
  height: toRem(560);
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -2;
}

/* cookie */
.cookie {
  width: 100%;
  min-height: toRem(50);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: $ap-point-skyblue;
  z-index: 999;
}

.ckNoiceWrap {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.ckieLink {
  margin-left: toRem(20);
}

.ckNoiceWrap .cautionWrap {
  margin-top: 0;
}

.ckNoiceWrap .cautionWrap .title {
  margin-bottom: 0;
}

.taskButton.coockieBtn {
  width: toRem(100);
  height: toRem(35);
  margin-left: toRem(200);
  color: $ap-text-black;
  font-weight: 300;
  border: solid toRem(1) $ap-line-black;
  background: transparent;
}

.popTwoBtnW button {
  width: 45%;
}

@include rwd('ul') {
  .fltConVtc h3 {
    font-size: toRem(28);
    margin-bottom: toRem(24);
  }
  .fltConVtc .imgVtcBg {
    display: none;
  }
  .mainWrap .bg {
    height: toRem(432);
  }
  .heroWrap .homeTicket {
    width: toRem(360);
    height: toRem(432);
  }
  .heroWrap .homeTicket .vertical {
    padding: toRem(12) toRem(36);
  }
  .fltConVtc .bottom {
    margin-top: toRem(24);
  }
  .tripType {
    margin-bottom: toRem(8);
  }
  .fltConVtc .middle .slctCon {
    left: toRem(305);
  }
  .sectionWrapper .titleWrap .title h3 {
    font-size: toRem(24);
  }
  .mainSecEvent ul {
    gap: toRem(16);
  }
}

@include rwd('xl') {
  .fltConVtc h3 {
    font-size: toRem(28);
    margin-bottom: toRem(24);
  }
  .fltConVtc .imgVtcBg {
    display: none;
  }
  .mainWrap .bg {
    height: toRem(432);
  }
  .heroWrap .homeTicket {
    width: toRem(360);
    height: toRem(432);
  }
  .heroWrap .homeTicket .vertical {
    padding: toRem(12) toRem(36);
  }
  .fltConVtc .bottom {
    margin-top: toRem(24);
  }
  .tripType {
    margin-bottom: toRem(8);
  }
  .mainSecEvent ul {
    gap: toRem(16);
  }
  .fltConVtc .middle .slctCon {
    left: toRem(305);
  }
}

@include rwd('lg') {
  .fltConVtc h3 {
    font-size: toRem(28);
    margin-bottom: toRem(24);
  }
  .fltConVtc .imgVtcBg {
    display: none;
  }
  .mainWrap .bg {
    height: toRem(432);
  }
  .heroWrap .homeTicket {
    width: toRem(360);
    height: toRem(432);
  }
  .heroWrap .homeTicket .vertical {
    padding: toRem(12) toRem(36);
  }
  .fltConVtc .bottom {
    margin-top: toRem(24);
  }
  .tripType {
    margin-bottom: toRem(8);
  }
  .mainSecEvent ul {
    gap: toRem(16);
  }
  .fltConVtc .middle .slctCon {
    left: toRem(305);
  }

}

@include rwd('md') {
  .fltConVtc h3 {
    font-size: toRem(26);
    margin-bottom: toRem(24);
  }
  .fltConVtc .imgVtcBg {
    display: none;
  }
  .mainWrap .bg {
    height: toRem(432);
  }
  .heroWrap .homeTicket {
    width: toRem(336);
    height: toRem(432);
  }
  .heroWrap .homeTicket .vertical {
    padding: toRem(12) toRem(32);
  }
  .heroWrap .homeTicket .angle span {
    width: toRem(24);
    height: toRem(27);
    background-size: toRem(24);
  }
  .heroWrap .homeTicket .angle span.left {
    left: toRem(-24);
  }
  .heroWrap .homeTicket .angle span.right {
    right: toRem(-24);
  }

  .fltConVtc .bottom {
    margin-top: toRem(24);
  }
  .tripType {
    margin-bottom: toRem(8);
  }
  .mainSecEvent ul {
    gap: toRem(16);
    flex-wrap: wrap;
    align-content: stretch;
  }
  .mainSecEvent .list {
    width: calc(50% - #{toRem(8)});
  }
  .fltConVtc .bottom .taskButton .iconWrap {
    font-size: toRem(15);
  }
  .fltConVtc .bottom .taskButton .iconWrap .icon {
    width: toRem(18);
    height: toRem(18);
    background-size: toRem(18);
  }
  .fltConVtc .middle .slctCon {
    left: toRem(290);
  }
}

@include rwd('sm') {
  .mainNotice {
    height: toRem(50);
  }
  .mainNotice .txt {
    font-size: toRem(13);
  }

  .heroWrap .hero {
    justify-content: center !important;
  }

  .fltConVtc .middle .station .slctCon {
    width: 100%;
    padding: toRem(10) 0;
    top: toRem(50);
    max-height: toRem(212);
    left: 0;
  }


  /* .mainSecEvent */
  .mainSecEvent ul {
    flex-flow: wrap;
  }
  .mainSecEvent .list h4 {
    font-size: toRem(16);
  }
  .mainSecEvent .list {
    width: calc(50% - #{toRem(10)});
    margin-bottom: toRem(40);
  }
  .mainSecEvent .list:nth-child(3),
  .mainSecEvent .list:nth-child(4) {
    margin-bottom: 0;
  }
  .mainSecEvent .list .date {
    font-size: toRem(14)
  }
  .mainSecEvent .list .badgeWrap .badge {
    font-size: toRem(14);
  }


  /* .mainSecNotice */
  .mainSecNotice h4 {
    font-size: toRem(16);
  }
  .mainSecNotice li .desc {
    font-size: toRem(14);
  }
  .mainSecNotice li .date {
    display: none;
  }
  .mainSecNotice li {
    padding: toRem(20) 0;
  }
  .mainSecNotice .title {
    width: 100%;
  }
  .mainSecNotice .desc {
    font-size: toRem(13);
  }
  .mainSecNotice .date {
    display: none;
  }


  /* .mainDarkSiteNotice */
  .mainDarkSiteNotice {
    padding: toRem(20) toRem(25) toRem(40);
  }
  .mainDarkSiteNotice .DarkSiteTag {
    margin-bottom: toRem(20);
    font-weight: 700;
  }
  .mainDarkSiteNotice .txt {
    font-size: toRem(14);
  }
  .mainDarkSiteNotice .DarkSiteTitle {
    font-size: toRem(16);
  }
  .mainDarkSiteNotice .arrow {
    bottom: toRem(14);
  }
  .mainDarkSiteNotice.on {
    padding: toRem(30) toRem(25) toRem(50);
  }
  .mainDarkSiteNotice.on .DarkSiteTitle {
    margin-bottom: toRem(20);
    font-size: toRem(20);
  }

  .ckNoiceWrap {
    flex-direction: column;
  }
  .ckNoiceWrap .cautionWrap {
    margin-bottom: toRem(10);
  }
  .taskButton.coockieBtn {
    margin: 0 auto;
  }
}

/****************
**** 예매 공통 ****
****************/


/* stepper */
.stepperTicket {
  height: toRem(16);
  display: flex;
  align-items: center;
}

.stepperTicket .stpcl {
  width: toRem(12);
  height: toRem(12);
  position: relative;
  line-height: 1;
}

.stepperTicket .stpcl .icon {
  position: absolute;
  top: 0;
  cursor: default;
}

.stepperTicket .stpcl .icon.dotdn {
  width: toRem(12);
  height: toRem(12);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/dot_done.svg) no-repeat;
  background-size: toRem(12);
}

.stepperTicket .stpcl .icon.dotdo {
  width: toRem(12);
  height: toRem(12);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/dot_doing.svg) no-repeat;
  background-size: toRem(12);
}

.stepperTicket .stpcl .icon.dotun {
  width: toRem(12);
  height: toRem(12);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/dot_undone.svg) no-repeat;
  background-size: toRem(12);
}

.stepperTicket .stpcl .text {
  position: absolute;
  top: toRem(20);
  left: 50%;
  display: block;
  font-size: toRem(14);
  white-space: nowrap;
  transform: translateX(-50%);
}

.stepperTicket .stpline {
  width: toRem(17.5);
  height: toRem(2);
  margin: 0 toRem(8);
  display: block;
  background: $ap-text-black;
}

.stepperTicket .stpline.un {
  height: toRem(1);
  background: $ap-text-gray-3;
}

@include rwd('sm') {
  .stepper {
    margin: 0 auto toRem(30);
  }
  .stepperTicket .stpcl .text {
    top: toRem(-20);
  }
  .stepperTicket .stpclBottomText .text {
    top: toRem(20) !important;
  }
}


/* underWrap */
.fltTab {
  width: 100%;
  background-color: $ap-white;
}

.fltTab .underWrap {
  width: 100%;
  min-width: toRem(320);
  height: toRem(100);
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ap-white;
  box-shadow: rgba(0, 0, 0, 0.04) 0 toRem(-12) toRem(15);
  z-index: 50;
}

.underWrap .cont {
  max-width: toRem(1280);
  width: 100%;
  display: flex;
  place-content: center space-between;
}

.underWrap .rounded {
  width: 100%;
  padding: 0;
  display: block;
  font-weight: 400;
  text-align: left;
  border: 0;
}

.rounded .dftBtn {
  height: toRem(50);
  padding: 0 toRem(30);
  color: $ap-solid-darkblue;
  font-size: toRem(16);
  font-weight: 500;
  line-height: toRem(48);
  text-align: center;
  background-color: $ap-white;
  border: toRem(2) solid $ap-solid-darkblue;
  border-radius: toRem(8);
}

.underWrap .prmCode {
  display: flex;
  align-items: center;
}

.underWrap .prmCode.hidden {
  visibility: hidden;
  height: 0px;
  width: 10px;
}

.underWrap .prmCode .rounded.on .dftBtn {
  padding: 0;
  color: $ap-text-black;
  line-height: 1.5em;
  text-align: left;
  background: transparent;
  border: 0;
}

.underWrap .prmCode .dftBtn .title {
  color: $ap-solid-skyblue;
  font-size: toRem(14);
}

.underWrap .prmCode .dftBtn .text {
  font-size: toRem(16);
}

.underWrap .prmCode .dftBtn .text > span {
  display: inline-block;
  vertical-align: middle;
}

.underWrap .prmCode .dftBtn .text .name {
  max-width: toRem(300);
  margin-right: toRem(4);
}

.underWrap .prmCode .dftBtn .text .code {
  font-weight: 700;
  white-space: nowrap;
}

.underWrap .prmCode .prmDel {
  margin-top: toRem(23);
  margin-left: toRem(8);
}

.underWrap .prcstpBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.prcRgt {
  margin-right: toRem(50);
  display: flex;
  align-items: center;
  position: relative;
}

.prcRgt.point {
  margin-top: toRem(0);

  &.marginTopLarge {
    margin-top: toRem(0);
  }
}

.prcRgt h3 {
  margin-left: toRem(30);
  color: $ap-point-orange;
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: toRem(-0.36);
  text-decoration: underline;
}

.prcRgt .prcSpeechBubble {
  position: absolute;
  width: toRem(320);
  bottom: toRem(46);
  opacity: 0;
  animation: fadein 0.5s 0.5s forwards;
  -moz-animation: fadein 0.5s 0.5s forwards; /* Firefox */
  -webkit-animation: fadein 0.5s 0.5s forwards; /* Safari and Chrome */
  -o-animation: fadein 0.5s 0.5s forwards; /* Opera */
}

.prcRgt .prcSpeechBubble .noticeText {
  background: $ap-point-orange;
  box-shadow: 0 toRem(1) toRem(4) rgba(0, 0, 0, 0.3);
  border-radius: toRem(30);
  padding: toRem(8) toRem(16);
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(19);
  color: $ap-white;
  text-align: center;
}

.prcRgt .prcSpeechBubble .bubbleTail {
  background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/img_speech_bubble_tail.svg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  position: absolute;
  margin-top: toRem(-1);
  right: toRem(90);
  width: toRem(14);
  height: toRem(15);

}

.prmOn .prmGrp {
  display: flex;
  align-items: center;
}

.prmOn .proSale {
  margin-left: toRem(20);
  text-decoration: line-through;
  color: $ap-text-gray-1;
}

.prmOn .proArr {
  margin: 0 toRem(10);
  color: $ap-point-orange;
}

.prmOn .prmGrp h3 {
  margin-left: 0;
}

.underWrap .stpBtn {
  place-content: center space-between;
}

.underWrap .stpBtn .taskButton {
  width: toRem(150);
  margin-right: toRem(10);
  cursor: pointer;
}

.underWrap .stpBtn .taskButton:last-child {
  margin-right: 0;
}

/* 인원선택 */
.passengersDropdown {
  width: toRem(400);
  padding-top: toRem(30);
}

.countItm {
  padding: toRem(10) toRem(25);
  margin-bottom: toRem(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.countItm.needSeatInf {
  background: $ap-text-gray-5;
}

.countItm.needSeatInf .psgrTyp {
  padding-left: toRem(20);
  font-size: toRem(14);
}

.psgrCon .ftG {
  margin-top: toRem(5);
  font-size: toRem(14);
}

.qttCntWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: toRem(160);
}

.qttCntWrap.txtConRt {
  flex-basis: auto;
  justify-content: flex-end;
  white-space: pre-line;
}

.qttCntWrap .iconWrap .icon {
  width: toRem(40);
  height: toRem(40);
  background-size: toRem(40);
}

.qttCntWrap .counter {
  width: toRem(54);
  height: toRem(54);
  color: $ap-text-black;
  font-size: toRem(18);
  line-height: toRem(54);
  text-align: center;
  border-radius: 50%;
  border: toRem(1) solid $ap-line-gray;
  font-weight: 700;
}

.qttCntWrap .counter.active {
  color: $ap-white;
  background: $ap-point-orange;
  border: toRem(1) solid $ap-point-orange;
}

.qttCntWrap .txtIpCont {
  width: toRem(40);
  border-bottom: 0;
}

.qttCntWrap .lb01IW {
  padding-bottom: toRem(5);
}

.qttCntWrap .lb01IW input {
  font-size: 2.4rem;
  text-align: center;
}

.agContWrap {
  margin-top: toRem(60);
  padding: 0 toRem(17) 0 toRem(25);
  margin-bottom: toRem(20);
}

.agCont {
  color: $ap-text-black;
  font-size: toRem(14);
  text-decoration: underline;
}

.popScr.agIpt {
  padding-bottom: toRem(60);
}

.agIpt .inputWrapper {
  height: auto;
  padding: toRem(5) 0 toRem(10);
}

.agRstWrap {
  padding-top: toRem(20);
  text-align: center;
}

.agRstTxt {
  padding: 0 toRem(8);
  margin: 0 toRem(3);
  position: relative;
  color: $ap-solid-orange;
  font-weight: 700;
}

.agRstTxt::before,
.agRstTxt::after {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.agRstTxt::before {
  content: '[';
  left: 0;
}

.agRstTxt::after {
  content: ']';
  right: 0;
}

.pBtmWrap {
  padding: toRem(25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: toRem(1) solid $ap-line-gray;
}

.pBtmWrap .description {
  padding: 0 toRem(40) 0 toRem(20);
  position: relative;
}

.pBtmWrap .description.ftG {
  font-size: toRem(14);
  line-height: 1.4em;
}

.pBtmWrap .lg_btn {
  width: toRem(80);
  height: toRem(36);
}

.pBtmWrap .lg_btn .taskButton {
  height: 100%;
}

.dtPsgr .passengers .slctCon {
  left: auto;
  right: toRem(-81);
}

.fltConVtc .middle .passengers .slctCon {
  top: toRem(-374);
}

@include rwd('ul') {
  .fltConVtc .middle .passengers .slctCon {
    top: toRem(-294);
  }
}

@include rwd('md') {
  .fltConVtc .middle .passengers .slctCon {
    top: toRem(-280);
  }
}

/* 나이계산기 팝업 */
.ageTxtCon {
  text-align: center;
}

.ageTxtCon > p {
  margin-top: toRem(20);
  line-height: 1.4em;
}

.ageTxtCon > p > .ageDiv {
  color: $ap-solid-orange;
  font-weight: 600;
  position: relative;
}

.ageTxtCon > p > .ageDiv:before {
  content: '[';
}

.ageTxtCon > p > .ageDiv:after {
  content: ']';
}


/* 왕복,편도 버튼 */
.tripType {
  margin-bottom: toRem(20);
}

.tripType button {
  font-size: toRem(14);
  padding: 0 toRem(14);
  line-height: toRem(28);
  border-radius: toRem(15);
  color: inherit;
}

.tripType button:first-child {
  padding-left: 0;
}

.tripType button.on {
  color: $ap-white;
  font-weight: 700;
  background: $ap-solid-darkblue;
}

.tripType button.on:first-child {
  padding-left: toRem(14);
}

@include rwd('xl') {
  .underWrap .cont {
    max-width: 100%;
    width: 100%;
    padding: 0 toRem(40);
  }
}

@include rwd('lg') {
  /* 인원선택 */
  .passengersDropdown {
    width: toRem(350);
  }
  .pBtmWrap .description {
    padding-right: toRem(10);
  }
  //.fltConVtc .middle .passengers .slctCon {
  //  top: toRem(-315);
  //}
  .slctBox.passengers > .slctCon.pop {
    right: auto;
  }


  /* 왕복,편도 버튼 */
  .tripType {
    //margin-bottom: toRem(10);
  }
  .tripType button {
    //font-size: toRem(14);
  }
}

@include rwd('md') {
  /* underWrap */
  .fltTab {
    margin-bottom: toRem(30);
  }
  .underWrap .cont {
    padding: 0 toRem(25);
  }
  .prcRgt.prmOn {
    margin-right: toRem(10);
  }
  .prmOn .proSale {
    margin-left: toRem(5);
  }
  .prmOn .proArr {
    margin: 0 toRem(5);
  }
  .prmOn .prmGrp h3 {
    font-size: toRem(20);
  }
  .prmOn .prMot span {
    font-size: toRem(16);
  }
  .rounded .dftBtn {
    padding: 0 toRem(15);
  }
  .underWrap .stpBtn .taskButton {
    width: toRem(100);
    margin-right: toRem(5);
  }
}


@media only screen and (max-width: 799px) {
  /* 인원선택 */
  .passengersDropdown {
    width: toRem(300);
  }
}

@include rwd('sm') {
  /* underWrap */
  .fltTab .underWrap {
    height: auto;
  }
  .rounded .dftBtn {
    font-size: toRem(14);
  }
  .underWrap .cont {
    padding: toRem(20);
    display: block;
  }
  .underWrap .prcstpBtn {
    display: block;
  }
  .underWrap .prmCode .rounded {
    min-width: toRem(170);
    margin: 0 auto;
  }
  .underWrap .prmCode .rounded.on {
    width: 100%;
    margin: 0;
  }
  .underWrap .prmCode .dftBtn .title {
    font-size: toRem(13);
  }
  .underWrap .prmCode .dftBtn .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: toRem(14);
  }
  .underWrap .prmCode .dftBtn .text .name {
    width: calc(100% - #{toRem(100)});
    max-width: unset;
    margin-right: 0;
  }
  .underWrap .prmCode .dftBtn .text .code {
    width: toRem(90);
    display: inline-block;
    text-align: right;
  }
  .underWrap .prmCode .prmDel {
    margin-top: toRem(13);
  }
  .prcRgt {
    margin: toRem(20) 0 toRem(10);
    flex-direction: column;
    position: relative;
  }
  .prcRgt p {
    width: 100%;
  }
  .prcRgt h3 {
    width: 100%;
    margin-left: 0;
    text-align: right;
  }
  .prcRgt .prcSpeechBubble {
    right: 0;
    //width: 100%;
    //min-width: toRem(380);
    //max-width: toRem(324);
  }
  .prcRgt .prcSpeechBubble .noticeText {
    font-size: toRem(12);
    line-height: toRem(16);
  }
  .underWrap .stpBtn .taskButton {
    width: 100%;
  }
  .prcRgt.prmOn {
    display: block;
  }
  .prcRgt.marginTopLarge {
    margin-top: toRem(40);
    transition: margin 0.2s;
  }
  .prmOn .prmGrp {
    justify-content: flex-end;
  }
  .prmOn .proArr {
    margin: 0 toRem(5);
  }
  .prmOn .prmGrp h3 {
    width: unset;
  }
  /* 인원선택 */
  .passengersDropdown {
    width: 100%;
    height: 100%;
    padding-top: toRem(50);
    position: relative;
  }
  .passengersDropdown .btnCal.close {
    display: block;
  }
  .agContWrap {
    margin: 0;
    position: absolute;
    bottom: toRem(110);
  }
  .pBtmWrap {
    width: 100%;
    height: toRem(90);
    position: absolute;
    bottom: 0;
  }
  .countItm {
    height: toRem(70);
  }
  .countItm:first-child {
    margin-top: 0;
  }
  .psgrCon .ftG {
    font-weight: 400;
  }
  .qttCntWrap {
    flex-basis: auto;
  }
  .qttCntWrap .counter {
    width: toRem(40);
    height: toRem(40);
    line-height: toRem(40);
  }
  .passengers .slctCon {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    inset: 0;
    border: 0;
    border-radius: 0;
    z-index: 111;
  }
  .slctBox.passengers > .slctCon.pop,
  .fltConVtc .middle .passengers .slctCon {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@include rwd('xs') {
  /* underWrap */
  .prmOn {
    display: block;
  }
  .prmOn p {
    width: 100%;
  }
  .prmOn .prmGrp {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 359px) {
  .tripType {
    display: block;
  }
  .toggleWrap.flex {
    width: 100%;
    margin-top: toRem(10);
    display: block;
    text-align: right;
  }
  .tripType .toggleWrap .container {
    width: auto;
  }
}


/**************
***** 예매 *****
***************/


/* 01 예매 첫 화면 */
.fltBgWrap {
  margin-top: toRem(60);
}

.flightWrap .comPd {
  padding: 0 2.5rem;
}

.fltBgWrap .subTit {
  margin-bottom: toRem(40);
  line-height: 1.6em;
}

.fltBgWrap .buttonWrapper .taskButton {
  width: fit-content;
  min-width: 10.875rem;
  padding: 0.75rem 1.2rem;

  @include rwd("xl") {
    min-width: auto;
  }
}

.fltCon {
  width: 100%;
  height: toRem(100);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: toRem(1) solid $ap-line-black;
  border-radius: toRem(8);
  padding: 0 20px;

  .divider {
    width: 1px;
    height: 20px;
    display: block;
    background: $ap-line-gray;
    margin: 0 20px;

    @include rwd("lg") {
      display: none;
    }
  }
}

.fltCon::after {
  content: '';
  width: toRem(144);
  height: toRem(140);
  display: block;
  position: absolute;
  bottom: toRem(99);
  right: toRem(40);
}

.sctDpAr,
.dtPsgr {
  display: flex;
  align-items: center;
  justify-content: center;
}


.sctDpAr {
  position: relative;


  .slctBox {
    .val {
      color: $ap-text-black;
    }

    .arCd {
      font-size: toRem(26);
      font-weight: 700;
      line-height: toRem(36);
      letter-spacing: -0.1rem;
    }

    .arNm {
      display: block;
      font-size: toRem(14);
      font-weight: 400;
      white-space: nowrap;
    }

    > ul {
      width: toRem(300);
      top: toRem(65);
    }
  }
}

.sctDpAr .slctCon.popRgt {
  left: unset;
}

.dtPsgr .dateWrapper,
.dtPsgr .passengers {
  width: auto;
  min-width: toRem(220);
}

.dtPsgr {
  flex: 1;
  max-width: 45%;
  justify-content: space-between;

  .dateWrapper {
    position: relative;
    display: flex;

    button.val {
      font-size: toRem(18);
      line-height: 1.2em;
    }
  }

  .passengers {
    display: flex;

    button.val {
      font-size: toRem(18);
      line-height: 1.2em;
    }
  }
}


.dtPsgr .dateWrapper.lstCr {
  margin-right: toRem(60);
}

.dtPsgr .dateWrapper.lstCr::after {
  right: toRem(-30);
}

.ticktRcpWrap {
  padding-bottom: toRem(20);
}

.rcpHead {
  font-size: toRem(22);
  font-weight: 700;
}

.cls {
  color: $ap-text-gray-2;
  font-weight: 700;
}

.rcpConWrap {
  padding: toRem(30) 0;
  border-top: toRem(2) solid $ap-line-black;
}

.rcpConWrap dl {
  margin-bottom: toRem(15);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rcpConWrap dl dd {
  font-weight: 700;
}

.rcpConWrap dl:last-child {
  margin-bottom: 0;
}

.totalWrap {
  padding-top: toRem(20);
  padding-bottom: toRem(30);
  font-size: toRem(20);
  font-weight: 700;
  border-top: toRem(2) dotted $ap-text-gray-4;
}

.payDt .totalWrap {
  padding-bottom: 0;
}

.totalWrap strong {
  color: $ap-point-orange;
  font-weight: 700;
}

.psgWrap li {
  margin-bottom: toRem(40);
  border-radius: toRem(8);
  border: toRem(1) solid $ap-text-gray-4;
}

.psgWrap li:last-child {
  margin-bottom: 0;
}

.psgWrap .psgInfoCon {
  padding: toRem(25);
}

.psgInfo .name {
  font-size: toRem(20);
  font-weight: 700;
}

.psgInfo .psgrTyp {
  padding-top: toRem(3);
  color: $ap-text-gray-2;
  font-weight: 700;
}

.psgInfoCon .totalWrap {
  padding: 0;
  padding-top: toRem(23);
  border: 0;
}

.psgInfoCon .totalWrap span {
  font-size: toRem(16);
}

.psgInfoCon .totalWrap strong {
  font-size: toRem(20);
}

.psgWrap .tgList .tgBtn {
  padding: toRem(15) 0;
  justify-content: center;
  font-weight: 700;
  text-align: center;
  text-indent: 0;
  border-top: toRem(1) solid $ap-text-gray-4;
}

.psgWrap .tgList .tgBtn .arrow {
  margin-left: toRem(3);
}

.psgWrap .tgList.tgLOn .icon.down {
  transform: rotate(180deg);
}

.psgWrap .tgCont {
  padding: toRem(44) toRem(25) toRem(40);
  background: $ap-text-gray-5;
  border-top: toRem(2) dotted $ap-text-gray-4;
}

.psgWrap .tgCont .rcpConWrap {
  padding: 0;
  padding-top: toRem(40);
  border-top: 0;
}

.psgWrap .tgCont .rcpConWrap dl {
  margin-bottom: toRem(8);
}

.psgWrap .tgCont .rcpConWrap dl dt {
  font-weight: 500;
}

.psgWrap .tgCont .totalWrap {
  padding-top: toRem(30);
  border-top: 0;
}

.psgWrap .tgCont .totalWrap span {
  font-size: toRem(16);
  font-weight: 600;
}


/* 결제완료 */
.payCplt .comTitle {
  margin-bottom: toRem(80);
  position: relative;
  z-index: 0;
}

.payCplt .comTitle::after {
  content: '';
  width: toRem(426);
  height: toRem(224);
  position: absolute;
  top: - toRem(60);
  left: toRem(25);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/seq_booking_fin_pc_35@2x.png') no-repeat;
  background-size: 100%;
  z-index: -1;
}

.payCplt .subTit {
  line-height: 1.8em;
}

.payCplt .subTit .sub {
  padding-left: 0;
  margin-top: toRem(14);
  align-items: baseline;
}

.payCplt .subTit .icon.caution {
  margin-right: toRem(5);
  position: relative;
  top: toRem(2);
}

.ticktPayCplt {
  padding: toRem(30) toRem(30) toRem(36);
  border: toRem(1) solid $ap-line-black;
  border-radius: toRem(8);
}

.ticktPayCplt .rcpHead {
  font-size: toRem(24);
  line-height: 1.6em;
}

.ticktPayCplt .rcpHead .itinArr {
  margin: 0;
  top: toRem(2);
  display: inline-block;
}

.dscWrap {
  padding-top: toRem(10);
  display: flex;
}

.dscWrap span {
  margin-right: toRem(17);
  position: relative;
  color: $ap-text-gray-1;
}

.dscWrap span::after {
  content: '';
  width: toRem(1);
  height: toRem(10);
  display: block;
  position: absolute;
  top: 50%;
  right: toRem(-9);
  transform: translateY(-50%);
  background: $ap-text-gray-4;
}

.dscWrap span:last-child::after {
  display: none;
}

.fltSmr {
  margin-top: toRem(30);
  padding: toRem(30) 0;
  font-weight: 700;
}

.fltSmr.arr {
  margin-top: 0;
  border-top: toRem(1) dashed $ap-text-gray-4;
}

.valueWrap {
  margin-top: toRem(5);
  display: flex;
  align-items: center;
}

.valueWrap li {
  padding-right: toRem(21);
  position: relative;
}

.valueWrap li::after {
  content: '';
  width: toRem(1);
  height: toRem(14);
  display: block;
  position: absolute;
  top: 50%;
  right: toRem(10);
  transform: translateY(-50%);
  background: $ap-text-gray-4;
}

.valueWrap li:last-child:after {
  display: none;
}

.ticktPayCplt .totalWrap {
  padding: 0;
  padding-top: toRem(36);
  border-top: toRem(1) solid $ap-line-gray;
}

.ticktPayCplt .totalWrap span {
  font-size: toRem(16);
}

.ticktPayCplt .totalWrap strong {
  font-size: toRem(20);
}

.payCpltBtnWrap .boxLnk {
  margin-top: toRem(20);
}

.fltCon .sctDpAr .slctBox.dp .val > div {
  text-align: center;
}

@include rwd('lg') {
  .fltCon {
    width: 100%;
    height: auto;
    padding: toRem(30);
    flex-direction: column;
    border: toRem(1) solid $ap-line-black;
  }
  .fltCon::after {
    bottom: 19.785rem;
  }
  .fltCon .sctDpAr {
    width: 100%;
    margin-bottom: toRem(30);
    justify-content: space-between;
  }
  .fltCon .sctDpAr::after {
    display: none;
  }
  .fltCon .sctDpAr .slctBox.ar > .slctCon {
    right: 0;
    left: auto;
  }
  .fltCon .sctDpAr .slctBox .val {
    justify-content: center;
  }
  .fltCon .sctDpAr .slctBox > .slctCon {
    max-height: toRem(212);
  }
  .fltCon .dtPsgr {
    width: 100%;
    max-width: 100%;
    padding: toRem(30) 0 0;
    border-top: toRem(1) solid $ap-line-gray;
    justify-content: space-between;
  }
  .fltCon .dtPsgr .passengers .slctCon {
    right: 0;
    left: auto;
  }
  .fltCon .dtPsgr .dateWrapper,
  .fltCon .dtPsgr .passengers {
    width: calc(50% - #{toRem(2)});
    min-width: 0;
  }
  .fltCon .dtPsgr .dateWrapper {
    margin-right: toRem(88);
  }
  .sctDpAr .slctCon.popLft {
    right: toRem(-222);
  }
  .sctDpAr .slctCon.popRgt {
    right: toRem(-5);
  }
  .fltBgWrap .buttonWrapper {
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 0.0625rem solid #e5e5e5;
  }
  .fltBgWrap .buttonWrapper .taskButton {
    margin: 0rem;
  }
}

@include rwd('sm') {
  .fltCon .dtPsgr .dateWrapper {
    margin-right: toRem(20);
  }
  .dtPsgr .dateWrapper::after {
    right: toRem(-10);
  }
  .dtPsgr .dateWrapper button.val,
  .dtPsgr .passengers button.val {
    font-size: toRem(14);
  }
  .fltCon::after {
    display: none;
  }
  .sctDpAr .slctBox button.val > i {
    display: none;
  }
  .fltImg {
    margin: 0;
  }
  .dateWrapper button.val,
  .passengers button.val {
    font-size: toRem(14);
  }


  .sctDpAr .slctCon.popLft {
    width: toRem(215);
    right: toRem(-156);
  }
  .sctDpAr .slctCon.popRgt {
    width: toRem(215);
  }
  .fltBgWrap .buttonWrapper {
    width: 100%;
  }
  .ticktRcpWrap .rcpHead {
    font-size: toRem(18);
  }
  .ticktRcpWrap .cls {
    font-weight: 500;
  }
  .payCplt .comTitle::after {
    width: toRem(213);
    top: toRem(-30);
    left: toRem(30);
  }
  .ticktPayCplt {
    padding: toRem(30);
  }
  .ticktPayCplt .rcpHead {
    font-size: toRem(20);
  }
  .valueWrap {
    margin-top: toRem(15);
    flex-wrap: wrap;
  }
  .valueWrap li {
    width: 100%;
    margin-bottom: toRem(15);
  }
  .valueWrap li::after {
    display: none;
  }
  .valueWrap li:last-child {
    margin-bottom: 0;
  }
  .ticktPayCplt .totalWrap {
    padding-top: toRem(20);
  }
  .ticktPayCplt .totalWrap span {
    font-size: toRem(14);
  }
  .ticktPayCplt .totalWrap strong {
    font-size: toRem(16);
  }
  .psgInfo .name {
    font-size: toRem(18);
  }
  .psgInfo .psgrTyp {
    font-weight: 500;
  }
  .payDt .totalWrap > span {
    font-size: toRem(14);
  }
}

@include rwd('xs') {
  .fltCon .sctDpAr .slctBox.dp .val {
    justify-content: flex-start;
  }
  .fltCon .sctDpAr .slctBox.ar .val {
    justify-content: flex-end;
  }
  .fltCon .sctDpAr .slctBox.dp .val > div {
    text-align: left;
  }
  .fltCon .sctDpAr .slctBox.ar .val > div {
    text-align: right;
  }
  .fltCon .sctDpAr .fltImg {
    width: toRem(60);
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight_l.svg') no-repeat center;
  }
  .psgInfoCon .totalWrap {
    display: block;
  }
  .psgInfoCon .totalWrap strong {
    margin-top: toRem(5);
    display: block;
    font-size: toRem(18);
  }
  .psgWrap .tgCont .rcpConWrap {
    padding-top: toRem(20);
  }
  .psgWrap .tgCont .totalWrap {
    display: block;
  }
  .psgWrap .tgCont .totalWrap span {
    font-size: toRem(14);
  }
  .psgWrap .tgCont .totalWrap strong {
    margin-top: toRem(10);
    display: block;
  }
}


/*******************
***** 포인트 예매 *****
*******************/
.fltPoint .fltBgWrap {
  margin-top: 0;
}

.fltPoint .fltBgWrap .subTit {
  margin-bottom: toRem(100);
}

.fltPoint .fltCon::after {
  display: none;
}

.inputWrap .row .value .valPoint {
  margin-right: toRem(10);
}

.inputWrap .row .value .valPoint .num {
  margin-right: toRem(4);
  margin-left: toRem(0);
}

.inputWrap .row .value .valBtn {
  width: toRem(70);
}


/****************
**** 클래스선택 ***
****************/


/* floatingWrap */
.floatingWrap {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  background-color: $ap-white;
  box-shadow: 0 toRem(12) toRem(15) rgba(0, 0, 0, 0.04);
  z-index: 111;
}

.floatingWrap .titleWrap {
  margin: toRem(25) auto;
}

.floatingWrap .titleWrap .title {
  display: flex;
  align-items: center;
  font-size: toRem(16);
  font-weight: 400;
  line-height: 1.6em;
  cursor: pointer;
}

.floatingWrap .titleWrap .title:after {
  content: "";
  width: toRem(12);
  height: toRem(12);
  display: block;
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_able_up.svg') no-repeat;
  transition: 0.3s ease;
}

.floatingWrap .titleWrap .title.on:after {
  transform: rotate(180deg);
}

.floatingWrap .itinerary {
  display: flex;
  align-items: center;
}

.floatingWrap .itinerary p {
  letter-spacing: toRem(-0.26);
}

.floatingWrap .itinerary p.origin {
  color: $ap-solid-orange;
  font-size: 1.125rem;
  font-weight: 700;
}

.floatingWrap .titleWrap .title .date {
  margin: 0 toRem(10);
  padding-left: toRem(10);
  position: relative;
  display: flex;
  align-items: center;
}

.floatingWrap .titleWrap .title .date:before {
  content: "";
  width: toRem(1);
  height: toRem(10);
  position: absolute;
  left: 0;
  top: calc(50% - #{toRem(5)});
  background-color: $ap-line-gray;
}

.floatingWrap .unFolderWrap {
  padding-bottom: toRem(30);
  position: absolute;
  top: toRem(76);
  left: 0;
  right: 0;
  display: none;
  background: $ap-white;
  box-shadow: 0 toRem(12) toRem(15) rgba(0, 0, 0, 0.04);
  z-index: 10;
}

.floatingWrap .unFolderWrap > div {
  width: toRem(1280);
  margin: 0 auto 0;
}

.floatingWrap .unFolderWrap .strong {
  padding-top: toRem(30);
  font-weight: 700;
  border-top: toRem(1) solid $ap-text-gray-4;
}

.floatingWrap .unFolderWrap .fltConWrap {
  margin-top: toRem(20);
}

.floatingWrap .unFolderWrap .caution {
  display: block;
}

.floatingWrap .unFolderWrap .description {
  margin-top: toRem(20);
}

.floatingWrap .unFolderWrap .pBtmWrap .description {
  margin-top: 0;
}

.floatingWrap .unFolderWrap .buttonWrapper {
  width: toRem(160);
  margin: toRem(30) auto 0;
}

.floatingWrap .unFolderWrap .fltCon::after {
  display: none;
}


/* cabinClassOneway */
.cabinClassOneway {
  padding-top: toRem(50);
}

.priceDate {
  width: 100%;
  height: toRem(100);
  padding: 0 toRem(30) 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.priceDate .swiper-wrapper {
  width: 100%;
}

.priceDate .swipBtn {
  height: toRem(100);
  margin-top: 0;
  top: 0;
  transform: translateY(0);
}

.swiper-container .swiper-notification {
  display: none;
}

.dateFieldWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateField {
  width: toRem(150);
  height: toRem(98);
  padding: toRem(5) 0;
  border-radius: toRem(8);
  opacity: 0.3;
  cursor: pointer;
}

.dateField.dis {
  cursor: inherit;
}

.dateField.on {
  opacity: 1;
  border: solid toRem(2) $ap-text-black;
  box-shadow: toRem(7) toRem(7) toRem(7) 0 rgba(0, 0, 0, 0.03);
}

.dateField .date {
  margin-bottom: 0;
  text-align: center;
}

.dateField .date h3 {
  font-size: toRem(18);
  font-weight: 500;
}

.dateField .price {
  font-family: 'Heebo', sans-serif;
  line-height: toRem(18);
  text-align: center;
}

.dateField p {
  width: toRem(70);
  padding-left: toRem(10);
  position: relative;
  display: inline-block;
  font-size: toRem(14);
  text-align: right;
}

.dateField p:before {
  content: "";
  width: toRem(6);
  height: toRem(6);
  position: absolute;
  top: toRem(9);
  left: 0;
  display: inline-block;
  border-radius: 50%;
}

.dateField .ey p:before {
  background-color: $ap-solid-skyblue;
}

.dateField .pe p:before {
  background-color: $ap-point-orange;
}

.cabinClassOneway .seperator {
  height: toRem(44);
  margin-top: toRem(45);
  border-bottom: toRem(2) solid $ap-line-black;
}

.classTicket {
  margin-top: toRem(60);
}


/* classTicketTop */
.classTicket .classTicketTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classTicket .timeWrap {
  width: toRem(370);
}

.classTicket .timeWrap .time {
  display: flex;
  align-items: center;
}

.classTicket .timeWrap .time > div {
  margin-right: toRem(20);
}

.classTicket .timeWrap .time .station .day {
  color: $ap-text-gray-1;
  font-weight: 700;
  font-size: toRem(14);
}

.classTicket .timeWrap .time .station .hour {
  font-weight: 700;
  font-size: toRem(24);
  line-height: 1;
}

.classTicket .timeWrap .time .middle {
  position: relative;
  line-height: 1;
}

.classTicket .timeWrap .time .middle sub {
  width: 100%;
  position: absolute;
  top: toRem(-20);
  left: 0;
  text-align: center;
}

.classTicket .timeWrap .airplane {
  display: flex;
  margin-top: toRem(18);
}

.classTicket .timeWrap .airplane sub {
  margin-right: toRem(16);
  color: $ap-text-gray-1;
  font-weight: 300;
}

.classTicket .timeWrap .airplane sub.link {
  text-decoration: underline;
  cursor: pointer;
}

.classTicket .ticketWrap {
  width: calc(100% - #{toRem(370)});
  flex: 1;
}

.classTicket .ticketBox {
  width: 100%;
  height: toRem(116);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: toRem(12);
  border: toRem(1) solid $ap-text-gray-4;
}

.classTicket .ticketBox .classType {
  padding: toRem(32) toRem(40);
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.classTicket .ticketBox .classRdo {
  display: flex;
  align-items: center;
}

.classTicket .ticketBox .classRdo i {
  width: toRem(18);
  height: toRem(24);
  display: inline-block;
}

.classTicket .ticketBox .classRdo i {
  background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_radio-01-inactive.svg');
}

.classTicket .ticketBox .classRdo.on i {
  background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_radio.svg');
}

.classTicket .ticketBox .classRdo span {
  margin-left: toRem(8);
  font-size: 1.125rem;
  font-weight: 700;
}

.classTicket .ticketBox .classType.ey {
  border-right: toRem(1) dotted $ap-text-gray-4;
}

.classTicket .ticketBox .ey .classRdo span {
  color: $ap-solid-skyblue;
}

.classTicket .ticketBox .pe .classRdo span {
  color: $ap-point-orange;
}

.classTicket .ticketBox .lowestPriceWrap {
  height: toRem(40);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.classTicket .ticketBox .price {
  font-size: toRem(22);
  letter-spacing: toRem(-0.48);
  font-weight: 700;
  padding-bottom: toRem(2);
}

.classTicket .ticketBox .price span {
  letter-spacing: toRem(-0.32);
  font-size: toRem(16);
  font-weight: 500;
}

.classTicket .ticketBox .leftSeat {
  height: toRem(18);
  font-size: 0.86rem;
  line-height: toRem(16);
  font-weight: 400;
  text-align: right;
  letter-spacing: -0.02em;
}

.leftSeat:before {
  content: "";
  width: toRem(6);
  height: toRem(6);
  margin-right: toRem(4);
  margin-bottom: toRem(2);
  display: inline-block;
  border-radius: 50%;
}

.leftSeat.ey:before {
  background-color: $ap-solid-skyblue;
}

.leftSeat.pe:before {
  background-color: $ap-solid-orange;
}

.classTicket .ticketBox .classType.off {
  cursor: default;
}

.classTicket .ticketBox .classType.off .classRdo i {
  background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_radio_01_disable_active.svg');
}

.classTicket .ticketBox .classType.off .price,
.classTicket .ticketBox .classType.off .classRdo span {
  color: $ap-text-gray-1;
}

/* classTicketBottom */
.classTicketBottom {
  margin-top: toRem(20);
  display: none;
}

.classTicketBottom .panelWrap {
  /* height: toRem(321); */
  border-radius: toRem(12);
  border: toRem(1) solid $ap-text-gray-4;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.classTicketBottom .specWrap {
  padding: toRem(40) toRem(30) toRem(30);
}

.classTicketBottom .specWrap .contents {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.classTicketBottom .specWrap .top h2 {
  font-size: toRem(24);
  line-height: 1;
  letter-spacing: toRem(-0.72);
}

.classTicketBottom .specWrap .top .spec {
  margin-top: toRem(20);
  display: flex;
}

.classTicketBottom .specWrap .top .spec img {
  width: toRem(18);
  height: toRem(18);
  margin-top: toRem(4);
}

.classTicketBottom .specWrap .top .spec p {
  margin-left: toRem(10);
  letter-spacing: toRem(-0.26);
}

.classTicketBottom .specWrap .top .spec p .sub {
  color: $ap-text-gray-1;
  display: block;
}

.classTicketBottom .cardWrap {
  width: 100%;
  display: flex;
  flex: 1;
}

.classTicketBottom .cardWrap .card {
  flex: 1;
  border-left: toRem(1) dotted $ap-text-gray-4;
}

.classTicketBottom .cardWrap .card .contents {
  width: 100%;
  height: 100%;
  padding: toRem(30);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
}

.classTicketBottom .cardWrap .card .top {
  padding: 0 toRem(10);
}

.classTicketBottom .cardWrap .card .top h5 {
  font-size: toRem(20);
  letter-spacing: toRem(-0.36);
  font-weight: 700;
}

.classTicketBottom .cardWrap .card .top sub {
  letter-spacing: toRem(-0.26);
}

.classTicketBottom .cardWrap .optionWrap {
  padding: 0 toRem(10);
}

.classTicketBottom .cardWrap .optionWrap .option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.1em;
  margin-top: toRem(15);
}

.classTicketBottom .cardWrap .optionWrap .option p:nth-child(1) {
  width: 55%;
  color: $ap-text-gray-1;
}

.classTicketBottom .cardWrap .optionWrap .option p:nth-child(2) {
  width: calc(45% - #{toRem(5)});
  font-weight: 500;
  text-align: right;
}

.classTicketBottom .cardWrap .card .bottom .price {
  width: 100%;
  margin-top: toRem(30);
  padding: toRem(15) 0;
  font-size: toRem(20);
  font-weight: 700;
  letter-spacing: toRem(-0.32);
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(8);
}

.classTicketBottom.ey .cardWrap .card .bottom .price span,
.classTicketBottom.pe .cardWrap .card .bottom .price span {
  font-size: toRem(14);
}

.classTicketBottom.ey .cardWrap .card .bottom .price .lineth {
  text-decoration: line-through;
  color: $ap-text-gray-1;
}

.classTicketBottom.ey .cardWrap .card.on .bottom .price .lineth {
  text-decoration: line-through;
  color: $ap-point-sky;
}

.classTicketBottom.pe .cardWrap .card .bottom .price .lineth {
  text-decoration: line-through;
  color: $ap-text-gray-1;
}

.classTicketBottom.pe .cardWrap .card.on .bottom .price .lineth {
  text-decoration: line-through;
  color: $ap-point-skin;
}

.classTicketBottom.ey .cardWrap .card.on .contents {
  background-color: $ap-point-skyblue-light;
}

.classTicketBottom.pe .cardWrap .card.on .contents {
  background-color: $ap-point-skin-light;
}

.classTicketBottom.ey .cardWrap .card .bottom .price {
  color: $ap-solid-skyblue;
  border-color: $ap-solid-skyblue;
}

.classTicketBottom.ey .cardWrap .card.on .bottom .price {
  color: $ap-white;
  background-color: $ap-solid-skyblue;
}

.classTicketBottom.pe .cardWrap .card .bottom .price {
  color: $ap-solid-orange;
  border-color: $ap-solid-orange;
}

.classTicketBottom.pe .cardWrap .card.on .bottom .price {
  color: $ap-white;
  background-color: $ap-solid-orange;
}

.classTicketBottom.ey .cardWrap .card.soldOut .contents {
  background: $ap-white;
  cursor: default;
}

.classTicketBottom.ey .cardWrap .card.soldOut .bottom .price {
  color: $ap-white;
  background: $ap-text-gray-4;
  border-color: $ap-text-gray-4;
  cursor: default;
}

.classTicketBottom.pe .cardWrap .card.soldOut .contents {
  background: $ap-white;
  cursor: default;
}

.classTicketBottom.pe .cardWrap .card.soldOut .bottom .price {
  color: $ap-white;
  background: $ap-text-gray-4;
  border-color: $ap-text-gray-4;
  cursor: default;
}


/* flightRule */
.classTicket .flightRule {
  margin-top: toRem(15);
  display: flex;
  justify-content: flex-end;
}


/* cautionWrap */
.cabinClassOneway .cautionWrap {
  margin-top: toRem(80);
}

.ssrWrapper .cautionWrap {
  margin-top: toRem(70);
}


/* 여정정보 팝업 - popFlgtInfo */
.flgtInfo .popDescription {
  margin-top: toRem(10);
}

.flgtInfoWrap {
  margin-bottom: toRem(40);
  padding: toRem(30) 0;
  border: toRem(2) solid $ap-line-black;
  border-left: 0;
  border-right: 0;
}

.fltDtInfo {
  margin-bottom: toRem(25);
  display: flex;
  flex-wrap: wrap;
}

.fltDtInfo li {
  margin-right: toRem(20);
  font-weight: 300;
  position: relative;
}

.fltDtInfo li::after {
  content: '';
  width: toRem(1);
  height: toRem(12);
  display: block;
  position: absolute;
  top: 50%;
  right: toRem(-10);
  transform: translateY(-50%);
  background: $ap-text-gray-1;
}

.fltDtInfo li:last-child::after {
  display: none;
}

.figure {
  flex-basis: toRem(30);
}

.figure .circle {
  width: toRem(8);
  height: toRem(8);
  margin-left: toRem(1);
  background: $ap-text-black;
  border-radius: 50%;
}

.figure .circle.up {
  margin-bottom: toRem(3);
}

.figure .svgBox {
  height: toRem(25);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/shape_dotted_line.svg') no-repeat;
}

.figure .circle.down {
  margin-top: toRem(3);
}

.tripInfoWrap {
  flex: 1 1 0%;
}

.tripInfoWrap .tripInfoCon {
  font-weight: 700;
  flex-wrap: wrap;
}

.tripInfoWrap .tripInfoCon:first-child {
  margin-bottom: toRem(17);
}

.flgtInfoResult dl {
  margin-bottom: toRem(5);
}

.flgtInfoResult dl:last-child {
  margin-bottom: 0;
}

.flgtInfoResult dl dd {
  font-size: toRem(20);
  font-weight: 700;
}


@media only screen and (max-width: 1365px) {
  /* floatingWrap */
  .floatingWrap .unFolderWrap {
    padding-left: toRem(40);
    padding-right: toRem(40);
  }
  .floatingWrap .unFolderWrap > div {
    width: auto;
  }

  /* classTicketBottom */
  .classTicketBottom.ey .cardWrap .card .bottom .price .lineth,
  .classTicketBottom.ey .cardWrap .card.on .bottom .price .lineth,
  .classTicketBottom.pe .cardWrap .card .bottom .price .lineth,
  .classTicketBottom.pe .cardWrap .card.on .bottom .price .lineth {
    display: block;
  }
}

@include rwd('lg') {
  /* floatingWrap */
  .classTicket .ticketWrap {
    width: 100%;
    padding-left: 0;
  }
  .classTicket .ticketBox .price {
    font-size: toRem(20);
  }
  .classTicket .ticketBox .price span {
    font-size: toRem(14);
  }
  .floatingWrap .title {
    font-size: toRem(14);
  }
  .floatingWrap .itinerary p {
    font-size: toRem(14);
  }
  .floatingWrap .unFolderWrap {
    padding: 0 toRem(40) toRem(30);
    top: toRem(55);
  }
  .floatingWrap .unFolderWrap > div {
    width: 100%;
    margin: toRem(20) auto 0;
  }
  .floatingWrap .unFolderWrap .strong {
    margin-top: toRem(15);
    padding-top: toRem(20);
  }
  .floatingWrap .unFolderWrap .buttonWrapper {
    margin-top: toRem(15);
  }


  /* classTicketTop */
  .classTicket .classTicketTop {
    display: block;
  }
  .classTicket .timeWrap {
    width: auto;
    margin-bottom: toRem(20);
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .classTicket .timeWrap .time > div:last-child {
    margin-right: 0;
  }
}

@include rwd('md') {
  .floatingWrap .title {
    font-size: toRem(14);
  }
  .floatingWrap .unFolderWrap {
    padding: 0 toRem(25) toRem(20);
  }
  .classTicket .specWrap .contents {
    height: auto;
  }
  .classTicket .cardWrap {
    display: block;
  }
  .classTicket .cardWrap .card {
    width: 100%;
    border-left: 0;
    border-top: toRem(1) dotted $ap-text-gray-4;
  }
  .classTicket .cardWrap .card .top {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .classTicket .cardWrap .card .top h5 {
    font-size: toRem(18);
  }
  .classTicket .cardWrap .card .top sub {
    font-size: toRem(13);
  }
  .classTicketBottom .panelWrap,
  .classTicket .cardWrap .card .contents {
    display: block;
    height: auto;
  }

  /* cabinClassOneway */
  .priceDate {
    height: auto;
  }
  .dateFieldWrap {
    width: 100%;
    display: block;
  }
  .swiper-slide .dateField {
    width: 24%;
    color: inherit;
  }

  /* classTicketBottom */
  .classTicketBottom.ey .cardWrap .card .bottom .price .lineth,
  .classTicketBottom.ey .cardWrap .card.on .bottom .price .lineth,
  .classTicketBottom.pe .cardWrap .card .bottom .price .lineth,
  .classTicketBottom.pe .cardWrap .card.on .bottom .price .lineth {
    display: inline-block;
  }
}

@include rwd('sm') {
  /* floatingWrap */
  .floatingWrap {
    align-items: baseline;
    flex-direction: column-reverse;
  }
  .floatingWrap .itinerary {
    margin-right: toRem(10);
  }
  .floatingWrap .itinerary p,
  .floatingWrap .itinerary p.origin,
  .floatingWrap .date p {
    font-size: toRem(14);
  }
  .floatingWrap .titleWrap .title .date {
    display: none;
  }
  .floatingWrap .unFolderWrap > div {
    width: 100%;
    margin: toRem(10) auto 0;
  }
  .floatingWrap .unFolderWrap .strong {
    margin-top: toRem(10);
    font-size: toRem(14);
  }
  .classTicket .cardWrap .card .contents {
    padding: toRem(20);
  }
  .classTicket .timeWrap .time .station .hour {
    font-size: toRem(20);
  }
  /* cabinClassOneway */
  .cabinClassOneway {
    padding-top: 0;
  }
  .swiper-slide .dateField {
    width: 30%;
  }
  .dateField .date h3 {
    font-size: toRem(14);
  }
  .dateField p {
    font-size: toRem(14);
  }

  /* classTicketTop */
  .classTicket .ticketBox {
    height: auto;
    display: block;
    overflow: hidden;
  }
  .classTicket .ticketBox .classType {
    width: 100%;
    padding: toRem(20);
    float: left;
  }
  .classTicket .ticketBox .classType.ey {
    border-right: 0;
    border-bottom: toRem(1) dotted $ap-text-gray-4;

  }
  .classTicket .ticketBox .classRdo span {
    font-size: toRem(16);
  }
  .classTicket .ticketBox .price {
    font-size: toRem(16);
  }
  .classTicket .ticketBox .price span {
    font-weight: 400;
  }
  .classTicket .specWrap {
    padding: toRem(20);
  }
  .classTicket .ticketBox .leftSeat {
    height: toRem(18);
    font-size: 0.86rem;
    line-height: toRem(16);
    font-weight: 400;
    text-align: right;
    letter-spacing: -0.02em;
  }

  /* classTicketBottom */
  .classTicketBottom .cardWrap .card .bottom .price {
    font-size: toRem(16);
  }
  .classTicketBottom .specWrap .top h2 {
    font-size: toRem(20);
  }

  /* 여정정보 팝업 - popFlgtInfo */
  .flgtInfoWrap {
    padding: toRem(24) 0;
    margin-bottom: toRem(20);
  }
  .flgtInfoResult dl dd {
    font-size: toRem(15);
  }
}

@include rwd('xs') {
  /* .floatingWrap .titleWrap .title .date {
		margin-left: 0;
		font-size: toRem(14);
	} */
  .swiper-slide .dateField {
    width: 48%;
  }
  .classTicket .timeWrap .time > div {
    margin-right: toRem(10);
  }
}


@media only screen and (max-width: 375px) {
  /* floatingWrap */
  .floatingWrap .itinerary {
    margin-right: toRem(10);
  }
  .floatingWrap .rsp {
    display: none;
  }
}


/********************
**** 탑승자 정보입력 ****
********************/
.pointPhone {
  flex: 0;
}

.pointPhone .userNum {
  color: $ap-text-gray-1;
}

.pointPhone .userNum .val {
  margin-left: toRem(4);
}

@include rwd('sm') {
  .userNum {
    padding-bottom: toRem(5);
  }
}


/****************
**** 부가서비스 ****
****************/


/* 부가서비스,결제 공통 */
.contWrapper {
  display: flex;
  justify-content: space-between;
}

.contWrapper .left {
  width: toRem(767);
  border-top: toRem(2) solid $ap-line-black;
}

.contWrapper .left.bdB {
  border-bottom: toRem(2) solid $ap-line-black;
}

.contWrapper .left .contents {
  padding-top: toRem(60);
}

.contWrapper .left .contents.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contWrapper .left .contents.bdT {
  border-top: toRem(2) solid $ap-line-black;
}

.contWrapper .left .subtitle {
  color: $ap-text-black;
  font-size: toRem(26);
  font-weight: 700;
  letter-spacing: toRem(-0.36);
}

@include rwd('sm') {
  .contWrapper .left .contents.flex {
    padding-top: toRem(30);
  }
}


/* 부가서비스 */
.contWrapper .left .panels {
  padding-top: toRem(20);
}

.panelWrapper {
  padding-top: toRem(10);
}

.panelWrapper:first-child {
  padding-top: 0;
}

.ssrExp {
  width: 100%;
  text-align: left;
  background-color: $ap-white;
  border: toRem(1) solid $ap-text-gray-1;
  border-radius: toRem(12);
}

.ssrWrapper {
  min-height: toRem(116);
  padding: toRem(32);
  display: flex;
  align-items: center;
}

.ssrlft {
  margin-right: toRem(24);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ssrlft .iconWrap {
  display: flex;
  align-items: center;
}

.ssrrgt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0%;
  flex-wrap: wrap;
}

.ssrrgt .titWrapper .title {
  color: $ap-text-black;
  font-size: toRem(20);
  font-weight: 700;
  letter-spacing: toRem(-0.4);
}

.ssrrgt .titWrapper .subtxt {
  padding-bottom: toRem(3);
  font-size: toRem(14);
  word-break: keep-all;
}

.ssrrgt .titWrapper .dftDesc {
  padding-top: toRem(4);
  font-size: toRem(14);
  letter-spacing: toRem(-0.3);
  line-height: 180%;
}

.ssrrgt .addList .compAdd {
  margin-right: toRem(20);
  position: relative;
  cursor: pointer;
}

.ssrrgt .addList .compAdd .ftO {
  margin-left: toRem(5);
}

.ssrrgt .addList .compAdd .compArr {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: toRem(-20);
  border: toRem(5) solid transparent;
  border-top-color: $ap-line-black;
}

.ssrrgt .addList .compAdd.on .compArr {
  top: auto;
  bottom: 50%;
  transform: rotate(180deg);
}

.ssrrgt .taskButton.small {
  min-width: toRem(128);
  height: toRem(48);
  font-size: toRem(16);
  padding: toRem(12) toRem(16);
  display: flex;
  justify-content: center;
  align-items: center;

}

.ctntWrapper {
  padding: 0 toRem(30);
  display: none;
  background: $ap-text-gray-5;
  border-top: toRem(1) dashed $ap-text-gray-4;
  border-bottom-left-radius: toRem(12);
  border-bottom-right-radius: toRem(12);
}

.ctntWrapper .cttCon {
  padding: toRem(30) 0;
  display: flex;
  align-items: flex-start;
  border-bottom: toRem(1) solid $ap-text-gray-4;
}

.ctntWrapper .cttCon:last-child {
  border-bottom: none;
}

.ctntWrapper .cttCon .badge {
  margin-right: toRem(25);
  flex-shrink: 0;
}

.ctntWrapper .cttCon .cttRgt {
  flex-grow: 1;
}

.ctntWrapper .cttCon .cttRgt .fltInfo {
  margin-bottom: toRem(15);
}

.ctntWrapper .cttCon .cttRgt .addInfo {
  display: flex;
  justify-content: space-between;
}

.ctntWrapper .cttCon .cttRgt .addInfo p {
  flex-basis: 0;
  flex-grow: 1;
}

.ctntWrapper .cttCon .cttRgt .addInfo .addSer {
  text-align: center;
}

.ctntWrapper .cttCon .cttRgt .addInfo .addPrc {
  text-align: right;
}

.notice {
  padding-top: toRem(20);
}

.lstgb {
  margin-top: toRem(5);
  position: relative;
  text-align: left;
}

.lstgb.star {
  padding-left: toRem(10);
}

.lstgb::before {
  content: "*";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.notice .lstgb p {
  line-height: 1.4rem;
}

.contWrapper .right {
  padding-left: toRem(60);
}

.contWrapper .receipt {
  position: sticky;
  top: toRem(180);
  z-index: 0;
}

.payReceipt {
  width: toRem(360);
  padding: toRem(32) toRem(32) toRem(40);
  background-color: $ap-white;
  border-radius: toRem(12);
  border: toRem(2) solid $ap-line-black;
}

.payReceipt .title {
  color: $ap-text-black;
  font-size: toRem(26);
  font-weight: 700;
  letter-spacing: toRem(-0.36);
}

.payReceipt .expWrapper {
  padding-top: toRem(32);
}

.payReceipt .expWrapper .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payReceipt .expWrapper .row .label {
  color: $ap-text-black;
}

.payReceipt .expWrapper .row .value {
  color: $ap-text-black;
  font-weight: 700;
}

.payReceipt .expWrapper .itemsWrapper .row {
  padding-top: toRem(15);
}

.payReceipt .expWrapper .itemsWrapper .row:first-child {
  padding-top: 0;
}

.payReceipt .expWrapper .sumsWrapper {
  margin-top: toRem(32);
  padding-top: toRem(32);
  border-top: toRem(2) solid $ap-line-black;
}

.payReceipt .expWrapper .sumsWrapper .row .label {
  font-weight: 700;
}

.payReceipt .expWrapper .sumsWrapper .row .value {
  color: $ap-point-orange;
  font-weight: 700;
}

.payReceipt .expWrapper .row .mlBf {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}

.payReceipt .expWrapper .currency {
  margin-top: toRem(10);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payReceipt .expWrapper .currency h5 {
  margin-right: toRem(11);
  font-size: toRem(16);
  font-weight: 700;
}

.payReceipt .expWrapper .currency .select {
  padding-left: toRem(10);
  font-size: toRem(14);
}

.payReceipt .expWrapper .currency .select .paymt {
  color: $ap-black;
  font-family: 'Heebo';
  font-weight: 300;
}

.payReceipt .expWrapper .currency .select > i {
  margin-left: toRem(5);
}

@include rwd('lg') {

  // 결제 금액
  .payReceipt {
    padding: toRem(32);
  }

  .payReceipt .title {
    font-size: toRem(18);
    line-height: 160%; /* 28.8px */
  }
  .payReceipt .expWrapper {
    padding-top: toRem(20);
    font-size: toRem(14);
  }
  .payReceipt .expWrapper .itemsWrapper .row {
    padding-top: toRem(8);
    font-size: toRem(14);
    line-height: 160%;
  }
  .payReceipt .expWrapper .row {

  }
  .payReceipt .expWrapper .sumsWrapper {
    margin-top: toRem(24);
    padding-top: toRem(24);
    border-top: toRem(2) solid $ap-line-black;
  }

  //부가서비스
  .ssrWrapper {
    min-height: toRem(96);
    padding: toRem(28);
  }

  .ssrlft {
    margin-right: toRem(16);
  }
  .ssrrgt .taskButton.small {
    min-width: toRem(64);
    width: fit-content;
    height: toRem(40);
    padding: toRem(10) toRem(16);
    font-size: toRem(14);
  }

  .contWrapper .right {
    padding-left: toRem(48);
  }

  .ctntWrapper .cttCon .cttRgt .addInfo {
    display: block;
    margin-bottom: toRem(20);
  }
  .ctntWrapper .cttCon .cttRgt .addInfo:last-child {
    margin-bottom: 0;
  }
  .ctntWrapper .cttCon .cttRgt .addInfo .psgName {
    font-weight: 700;
  }
  .ctntWrapper .cttCon .cttRgt .addInfo .addSer, .ctntWrapper .cttCon .cttRgt .addInfo .addPrc {
    text-align: left;
  }
}

@include rwd('md') {
  .payReceipt {
    padding: toRem(24) toRem(24) toRem(28);
  }

  .payReceipt .expWrapper .sumsWrapper {
    margin-top: toRem(20);
    padding-top: toRem(20);
  }

  .payReceipt .expWrapper {
    padding-top: toRem(20);
  }
  .ssrrgt {
    width: 100%;
  }
}

@include rwd('sm') {
  .ssrrgt .taskButton.small {
    width: fit-content;
  }
}

/**************
***** 좌석 *****
***************/


/* seatView */
.seatView {
  width: toRem(800);
  height: 100%;
  background-color: $ap-white;
}

.seatView .seatCont {
  height: 100%;
}

.seatCont .seatTop {
  height: toRem(165);
  padding: toRem(40) toRem(30) toRem(29);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.seatTop .tit {
  color: $ap-text-black;
  font-size: toRem(32);
  font-weight: 700;
  letter-spacing: toRem(-0.3);
}

.seatCont .seatMdl {
  height: calc(100vh - #{toRem(276)});
  overflow-y: auto;
  overflow-x: hidden;
  border-top: toRem(1) solid $ap-text-gray-3;
}

.seatMdl .innerWrapper {
  width: 100%;
  height: 100%;
  display: flex;

  &.hide-lft {
    .lft {
      display: none;
    }

    .rgt {
      width: 100%;
    }
  }
}

.seatMdl .innerWrapper .lft {
  width: toRem(250);
  padding: toRem(30);
  overflow-y: auto;
  border-top: none;
}

.innerWrapper .btnWrap {
  min-height: calc(100% - #{toRem(225)});
}

.seatMdl .innerWrapper .lft .buttonWrapper {
  min-width: toRem(110);
}

.psgBtn {
  width: 100%;
  height: toRem(70);
  padding: toRem(10) toRem(15) toRem(9);
  margin-bottom: toRem(10);
  color: $ap-solid-darkblue;
  font-weight: 700;
  text-align: left;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(8);
}

.psgBtn.on {
  color: $ap-white;
  background-color: $ap-solid-darkblue;
  border-color: $ap-solid-darkblue;
}

.psgBtn .name {
  font-size: toRem(20);
  font-family: 'Premia', 'sans-serif';
  font-weight: 700;
  letter-spacing: toRem(-0.36);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.psgBtn .label {
  height: toRem(20);
  padding-top: toRem(3);
  display: block;
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: toRem(-0.26);
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.psgBtn:last-child {
  margin-bottom: 0;
}

.innerWrapper .infoWrap {
  margin-top: toRem(30);
  padding: toRem(15) toRem(15) toRem(9);
  border: toRem(1) solid $ap-line-gray-light;
  border-radius: toRem(8);
}

.innerWrapper .infoWrap .info {
  margin-bottom: toRem(10);
  padding-left: toRem(22);
  position: relative;
}

.innerWrapper .infoWrap .info:last-child {
  margin-bottom: 0;
}

.innerWrapper .infoWrap .info.win > span {
  display: block;
  color: $ap-text-black;
  font-size: toRem(14);
}

.innerWrapper .infoWrap .info::before {
  content: "";
  width: toRem(16);
  height: toRem(22);
  position: absolute;
  left: 0;
  display: block;
  background-color: $ap-point-skyblue;
  border-radius: toRem(4) toRem(4) toRem(2) toRem(4);
}

.innerWrapper .infoWrap .info:nth-child(2)::before {
  background-color: $ap-point-info;
}

.innerWrapper .infoWrap .info:nth-child(3)::before {
  background-color: $ap-text-gray-2;
}

.innerWrapper .infoWrap .info.win::before {
  content: "!";
  color: $ap-text-gray-2;
  font-size: toRem(22);
  font-family: 'Premia', sans-serif;
  font-weight: 700;
  text-align: center;
  background-color: transparent;
}

.seatMdl .innerWrapper .rgt {
  width: calc(100% - #{toRem(250)});
  padding: toRem(30) toRem(50);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: toRem(1) solid $ap-text-gray-3;
}

.innerWrapper .rgt .iptsWrapper {
  width: toRem(449);
  flex: 1;
}

.innerWrapper .rgt .iptsWrapper > * {
  font-family: 'Premia', sans-serif;
}

.innerWrapper .rgt .ntcWrapper {
  padding-top: toRem(70);
}


/*  seatmap D */
.seatMap.seatmapD {
  .landmkTop .toilWrapper {
    height: toRem(42);
    padding-top: toRem(0);
  }

  .landmkMid .toilWrapper:nth-child(2) {
    height: toRem(100);
  }

  .landmkMid .toilWrapper:nth-child(2n+1) {
    height: toRem(42);
  }

  .landmkTop .toilWrapper {
    margin: 0;
  }

  .landmkTop .lbWrapper {
    margin-top: toRem(-68);
  }

  .seatBtnEmpty {
    width: toRem(32);
    height: toRem(44);
    position: relative;
  }

  .seatMapWrapper .group:nth-child(2) {
    margin-top: toRem(-121);
  }

  .seatMapWrapper.sct .group:nth-child(2) {
    margin-top: toRem(0);
  }

  .landmkBottom .row .galyWrapper {
    margin-top: toRem(0);
    width: toRem(106);
    height: toRem(42);
  }

  .landmkBottom {
    margin-top: toRem(16);
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row.items-end {
    align-items: flex-end;
  }

  .landmkMid {
    margin-top: toRem(-74);
  }

  .galyWrapper .stmapgaly {
    margin-top: toRem(6);
  }

  .landmkMid .lbWrapper {
    margin-top: toRem(-28);
  }

  .toilWrapper {
    width: toRem(108);
    height: toRem(42);
  }

  .pt-15 {
    padding-top: toRem(15);
  }

  .galyWrapper {
    width: toRem(106);
    height: toRem(42);
  }

  .landmkMid .clmnlbsWrapper {
    padding-top: toRem(18);
  }

  .clmnlbsWrapper {
    padding-top: toRem(4);
  }
}

.py-16 {
  padding: toRem(16) 0;
}

@include rwd('sm') {
  .seatMap.seatmapD {
    .landmkTop .lbWrapper {
      margin-top: toRem(-70);
    }

    .clmnlbsWrapper {
      padding-bottom: toRem(8)
    }

    .toilWrapper {
      width: toRem(106);
      height: toRem(42);
    }

    .galyWrapper {
      width: toRem(106);
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include rwd('xs') {
  .seatMap.seatmapD {
    .toilWrapper, .galyWrapper {
      width: toRem(86);
    }
  }
}

/* seatMap Economy */
.seatMap {
  position: relative;
  height: 100%;
}

.galyWrapper.three-block {
  width: toRem(106) !important;
}

.landmkTop .exitWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landmkTop .exitWrapper .galyWrapper {
  width: toRem(106);
  height: toRem(50);
}

.seatMap .clmnlbsWrapper {
  padding-top: toRem(20);
  display: flex;
  justify-content: space-between;
}

.exitLabel {
  display: inline-flex;
  align-items: baseline;
  flex-direction: row;
  color: $ap-point-orange;
}

.exitLabel.rev {
  flex-direction: row-reverse;
}

.exitLabel .trgWrapper {
  margin-top: toRem(0.5);
  position: relative;
}

.trgWrapper .trgCont {
  display: flex;
  align-items: center;
  transform: scale(1.6);
}

.trgWrapper i {
  width: toRem(12);
  height: toRem(12);
  overflow: hidden;
  text-indent: -9999px;
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_able_red.svg') no-repeat center;
  transform: rotate(90deg);
}

.trgWrapper i.rt {
  transform: rotate(270deg);
}

.exitLabel .label {
  font-size: toRem(20);
  color: $ap-red;
}

.landmkTop .toilWrapper {
  width: toRem(106);
  height: toRem(56);
  margin: 0 auto;
  padding-top: toRem(20);
}


.landmkTop.bet .row.line {
  padding-top: toRem(15);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landmkTop.bet .toilWrapper {
  height: toRem(44);
  padding: 0;
  margin: 0;
}

.landmkTop.bet .galyWrapper {
  width: toRem(106);
  height: toRem(44);
}

.seatMap .landmkTop .row .group {
  display: flex;
}

.seatMap .landmkTop .row .group.grLn {
  width: toRem(69);
}

.seatLandmk {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ap-white;
  border-radius: toRem(8);
  border: toRem(1) solid $ap-text-gray-3;
}

.seatLandmk .stmaptoil {
  width: toRem(26);
  height: toRem(28);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_seatmap_toilet.svg) no-repeat;
  background-size: toRem(26);
}

.seatLandmk .stmapgaly {
  width: toRem(26);
  height: toRem(28);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_seatmap_galley.svg) no-repeat;
}

.seatMap .labelsWrapper {
  position: relative;
  font-family: 'Premia', 'sans-serif';
  font-weight: 700;
}

.seatMap .labelsWrapper .clmnlbsWrapper .group.nNst {
  display: flex;
}

.seatMap .landmkTop.bet .labelsWrapper .clmnlbsWrapper {
  padding-top: toRem(19);
  align-items: center;
}

.seatMap .labelsWrapper .clmnlbsWrapper span {
  width: toRem(32);
  display: inline-block;
  font-family: 'Premia', sans-serif;
  color: $ap-text-black;
  font-size: toRem(22);
  text-align: center;
}

.seatMap .labelsWrapper .clmnlbsWrapper span + span {
  padding-left: toRem(5);
}

.seatMap .labelsWrapper .rowlbsWrapper {
  position: absolute;
  top: toRem(66);
  left: toRem(130);
  display: flex;
}

.seatMap .landmkTop.bet .labelsWrapper .rowlbsWrapper {
  top: toRem(59);
}

.seatMap .landmkMid .labelsWrapper .rowlbsWrapper {
  top: toRem(96);
}

.seatRow {
  display: flex;
  font-weight: 700;
  flex-direction: column;
}

.seatRow .row {
  height: toRem(44);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.seatRow .row + .row {
  margin-top: toRem(15);
}

.seatRow .row span {
  color: $ap-text-gray-3;
  font-family: 'Premia', sans-serif;
  font-size: toRem(18);
}

.seatMap .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
  padding-left: toRem(157);
}

.seatMap .landmkTop.bet .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
  margin-top: toRem(118);
}

.seatMap .seatMapWrapper .row {
  padding-top: toRem(15);
  display: flex;
  justify-content: space-between;
}

.seatMap .seatMapWrapper .row .group {
  display: flex;
}

.seatMap .seatMapWrapper .row .group .seatBtnWrapper {
  height: toRem(44);
}

.seatMap .seatMapWrapper .row .group .seatBtnWrapper + .seatBtnWrapper,
.seatMap .row .group .seatBtnWrapper + .seatBtnWrapper,
.seatMap .labelsWrapper .group .seatBtnWrapper + .seatBtnWrapper {
  padding-left: toRem(5);
}

.seatMap .seatBtnWrapper {
  position: relative;
}

.seatMap .landmkMid .lbWrapper .row > .noWd {
  position: relative;
}

.seatMap .landmkMid .lbWrapper .row > .noWd::before {
  content: "!";
  position: absolute;
  left: toRem(-7);
  display: block;
}

.seatMapWrapper.sct .row .group:nth-child(2) {
  margin-top: toRem(-20);
}

.seatMapWrapper.sct .row.bNn .group:nth-child(2) {
  margin-top: 0;
  margin-bottom: toRem(20);
}

.seatMapWrapper.sct .row.bNn .toilWrapper {
  width: toRem(106);
  height: toRem(56);
}

.seatBtn {
  width: toRem(32);
  height: toRem(44);
  position: relative;
  background-color: $ap-point-info;
  border-radius: toRem(8) toRem(8) toRem(4) toRem(4);
}

.seatBtn.on,
.seatBtn.sell.on,
.seatBtn.has {
  padding-top: toRem(10);
  color: $ap-white;
  font-size: toRem(20);
  font-family: 'Premia', sans-serif;
  font-weight: 700;
  background-color: $ap-point-orange;
}

.seatBtn.on:before,
.seatBtn.has:before {
  content: '';
  width: toRem(4);
  height: toRem(4);
  position: absolute;
  left: calc(50% - #{toRem(2)});
  top: toRem(8);
  display: block;
  background-color: $ap-white;
  border-radius: toRem(2);
}

.seatBtn.sell {
  background-color: $ap-point-skyblue;
}

.seatBtn.impos {
  background-color: $ap-text-gray-3;
}

.seatMap .landmkMid {
  padding-top: toRem(30);
}

.seatMap .landmkMid .row {
  display: flex;
  justify-content: space-between;
}

.seatMap .landmkMid .clmnlbsWrapper {
  padding-top: toRem(60);
}

.seatMap .landmkMid .toilWrapper:nth-child(2n+1) {
  width: toRem(106);
  height: toRem(56);
}

.seatMap .landmkMid .toilWrapper:nth-child(2) {
  width: toRem(108);
  height: toRem(106);
}

.seatLandmk .double {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.seatLandmk .double::before {
  content: "";
  width: toRem(1);
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: $ap-text-gray-3;
}

.seatLandmk .double .svgWrapper {
  width: 50%;
  text-align: center;
}

.landmkMid .labelsWrapper .clmnlbsWrapper .group:nth-child(2) {
  margin-top: toRem(-20);
}

.seatMap .landmkBottom {
  margin-top: toRem(31);
  position: relative;
  z-index: 1;
}

.seatMap .landmkBottom.bet {
  margin-top: toRem(20);
}

.seatMap .landmkBottom .row {
  display: flex;
  justify-content: space-between;
}

.seatMap .landmkBottom.bet .row {
  margin-top: toRem(10);
  align-items: center;
}

.seatMap .landmkBottom .row .sm {
  width: toRem(106);
  height: toRem(56);
}

.seatMap .landmkBottom .row .big {
  width: 100%;
  height: toRem(56);
  margin-top: toRem(30);
}

.seatMap .landmkBottom .row .galyWrapper {
  margin-top: toRem(39);
}

.seatCont .seatBottom {
  width: toRem(800);
  padding: toRem(15) toRem(30);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: $ap-white;
  border-top: toRem(1) solid $ap-text-gray-3;
  z-index: 1;
}

.seatBottom .prcWrapper {
  display: flex;
  align-items: center;
}

.seatBottom .prcWrapper.seat {
  width: unset;
  height: unset;
}

.seatBottom .btnsWrapper {
  display: flex;
}

.seatBottom .btnsWrapper .taskButton {
  //width: toRem(160);
  height: toRem(50);
  margin-right: toRem(10);
}

.seatBottom .btnsWrapper .taskButton:last-child {
  margin-right: 0;
}

/* seatMap premium */
.innerWrapper .rgt .iptsWrapper .seatMap.pre {
  width: toRem(373);
  margin: 0 auto;
}

.seatMap.pre .landmkTop .clmnlbsWrapper {
  display: flex;
  justify-content: space-between;
}

.seatMap.pre .landmkTop .row:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

.seatMap.pre .landmkTop .row:nth-child(1) .toilWrapper,
.seatMap.pre .landmkTop .row:nth-child(2) .toilWrapper {
  width: toRem(69);
  height: toRem(56);
  margin: 0;
  padding: 0;
}

.seatMap.pre .landmkTop .row:nth-child(1) .galyWrapper {
  width: toRem(242);
  height: toRem(56);
}

.seatMap.pre .landmkTop .row.stm {
  padding-top: toRem(30);
  display: flex;
  justify-content: space-between;
}

.seatMap.pre .landmkTop .row:nth-child(2) .galyWrapper {
  width: toRem(106);
  height: toRem(56);
}

.seatMap.pre .labelsWrapper .rowlbsWrapper {
  left: toRem(95);
}

.seatMap.pre .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
  padding-left: toRem(166);
}

.seatMap.pre .landmkBottom .row {
  margin-top: toRem(20);
}

.seatMap.pre .landmkBottom .row .galyWrapper {
  width: toRem(106);
  height: toRem(44);
  margin: 0;
}

.seatMap.pre .landmkBottom .row .sm {
  margin: 0 auto;
}

.seatMap .unable-msg {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: var(--Stroke-Grey20, #808080);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    margin: 16px;
  }
}

/* 기내식 */
.airlinefoodWrap {
  width: 100%;
  padding-bottom: toRem(100);
}

.airlinefoodWrap .tgList .tgBtn {
  height: toRem(64);
  font-size: 1.8rem;
  font-weight: 600;
  justify-content: space-between;
  border-bottom: toRem(1) solid $ap-line-gray;
}

.airlinefoodWrap .tgCont {
  padding-top: toRem(40);
  padding-left: 0;
}

.airlinefoodWrap .tgLOn .icon.arrow.black {
  transform: rotate(90deg);
  transition: ease 0.2s;
}

.foodConWrap.rdoBox {
  width: 100%;
  padding-left: 0;
}

.foodConWrap.rdoBox > input.ipt,
.foodConWrap.rdoBox:before {
  top: toRem(10);
  left: toRem(10);
}

.foodCon {
  margin-bottom: toRem(40);
  display: flex;
  align-items: center;
}

.svcImgBox {
  width: toRem(120);
  height: toRem(160);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $ap-text-gray-5;
  border-radius: toRem(8);
  overflow: hidden;
}

.svcImgBox img {
  width: toRem(70);
}

.svcInfo {
  width: calc(100% - #{toRem(130)});
  padding-left: toRem(20);
}

.svcInfo strong {
  margin-bottom: toRem(5);
  display: block;
  font-weight: 500;
}

.svcInfo > span {
  color: $ap-text-gray-2;
  font-size: toRem(14);
  line-height: 1.5em;
}

.svcInfo .prc {
  margin-top: toRem(10);
}

.svcInfo .prc span.HeeboB {
  font-size: 1.8rem;
}

.foodConWrap.rdoBox.on .svcInfo strong {
  color: $ap-point-orange;
}

.rdoWrap.nOp {
  margin-top: toRem(30);
}


/* 위탁 수하물 */
.chkBggWrap {
  width: 100%;
  padding-bottom: toRem(40);
}

.chkBggCon li {
  margin-bottom: toRem(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chkBgg {
  width: 100%;
  display: flex;
  align-items: center;
}

.chkBgg .svcInfo {
  padding-left: toRem(15);
}

.btnBox {
  margin-top: toRem(45);
  position: relative;
}

.btnBox a {
  font-size: toRem(14);
}

.cabinUbbtnWrap {
  position: relative;
  right: toRem(-12);
}

.rdoWrap.cabinRdoWrap {
  width: toRem(142);
  display: flex;
  flex-direction: column;
}

.cabinRdoWrap .rdoBox {
  margin-right: 0;
  margin-bottom: toRem(10);
}

.cabinRdoWrap .rdoBox:last-child {
  margin-bottom: 0;
}

.popBggPcsCont {
  padding: toRem(30) 0;
}

@include rwd('lg') {
  .popWrap .popCont .contWrap.seatWrap {
    left: 0;
    bottom: 0;
  }
  .popWrap .popCont .contWrap.seatSlt {
    width: 100%;
    display: block;
  }
  .seatView,
  .seatCont .seatBottom {
    width: 100%;
  }
}


@include rwd('md') {
  .contWrapper {
    display: block;
  }
  .contWrapper .left,
  .contWrapper .right,
  .payReceipt {
    width: 100%;
  }
  .contWrapper .right {
    padding-left: 0;
    margin-top: toRem(64);
  }
}

@include rwd('sm') {
  .contWrapper .left .contents {
    padding-top: toRem(32);
  }
  .contWrapper .left .subtitle {
    font-size: toRem(18);
  }
  .contWrapper .right {
    margin-top: toRem(32);
  }
  .ssrWrapper {
    min-height: 0;
    padding: toRem(24) toRem(20);
  }
  .ssrWrapper .icon {
    width: toRem(24);
    height: toRem(24);
    background-size: toRem(24);

  }
  .ssrlft {
    margin-right: toRem(16);
  }
  .ssrlft .iconWrap .icon {
    margin-left: 0;
    margin-right: 0;
  }
  .ssrrgt .titWrapper .title {
    font-size: toRem(16);
  }
  .payReceipt .title {
    font-size: toRem(18);
  }
  .payReceipt .expWrapper {
    padding-top: toRem(20);
  }
  .payReceipt .expWrapper .itemsWrapper .row {
    padding-top: toRem(5);
  }
  .seatCont .seatTop {
    height: toRem(90);
  }
  .seatTop .tit {
    font-size: toRem(26);
  }
  .seatCont .seatMdl {
    height: calc(100vh - #{toRem(230)});
  }
  .seatMdl .innerWrapper {
    flex-direction: column;
  }
  .seatMdl .innerWrapper .lft {
    width: 100%;
    padding: toRem(30) toRem(25);
    overflow: visible;
    border-bottom: toRem(1) solid $ap-text-gray-4;
  }
  .innerWrapper .btnWrap {
    min-height: auto;
  }

  .psgBtn {
    width: calc(50% - #{toRem(20)});
    margin-right: toRem(20);
    vertical-align: top;
  }
  .psgBtn:nth-child(2n) {
    margin-right: 0;
  }
  .psgBtn:nth-last-child(2) {
    margin-bottom: 0;
  }
  .innerWrapper .infoWrap {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .innerWrapper .infoWrap .info {
    width: calc(50% - #{toRem(10)});
    margin: toRem(10) toRem(10) 0 0;
    line-height: 1.4em;
  }
  .innerWrapper .infoWrap .info:nth-child(1),
  .innerWrapper .infoWrap .info:nth-child(2) {
    margin-top: 0;
  }
  .innerWrapper .infoWrap .info:nth-child(even) {
    margin-right: 0;
  }
  .seatMdl .innerWrapper .rgt {
    width: 100%;
    padding: toRem(50) toRem(25);
    overflow: visible;
    border-left: 0;
  }
  .seatBtn {
    width: toRem(30);
  }
  .seatCont .seatBottom {
    display: block;
  }
  .seatBottom .prcWrapper .prcRgt {
    width: 100%;
    margin: 0;
    margin-bottom: toRem(20);
    justify-content: space-between;
  }
  .seatBottom .prcWrapper .prcRgt h3 {
    font-size: 1.375rem;
  }
  .seatBottom .btnsWrapper .taskButton {
    width: 100%;
  }

  /* 기내식 */
  .airlinefoodWrap .tgList .tgBtn {
    font-size: toRem(16);
  }
  .svcImgBox {
    width: toRem(100);
    height: toRem(140);
  }
  .svcInfo {
    width: calc(100% - #{toRem(115)});
  }
  .foodCon {
    margin-bottom: toRem(20);
  }
}


@media only screen and (max-width: 499px) {
  .innerWrapper .rgt .iptsWrapper {
    width: 100%;
  }
  .innerWrapper .rgt .iptsWrapper .seatMap.pre {
    width: 100%;
  }
  .seatMap .labelsWrapper .rowlbsWrapper {
    width: 100%;
    left: 0;
  }
  .seatMap .labelsWrapper .rowlbsWrapper .lbWrapper {
    position: absolute;
    left: 31%;
  }
  .seatMap .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
    padding-left: 0;
    left: 68%;
  }
  .seatMap.pre .labelsWrapper .rowlbsWrapper {
    left: toRem(-20);
  }
  .seatMap.pre .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
    padding-left: toRem(35);
  }
  .seatRow {
    transform: translateX(-50%);
  }

  .prcRgt .prcSpeechBubble {
    right: toRem(-4);
    width: toRem(280);
    bottom: toRem(57);
  }
}


.seatMap .seatBtnWrapper .tooltip {
  display: flex;
  position: absolute;
  color: #222;
  font-size: toRem(13);
  padding: toRem(8) toRem(8) toRem(8) toRem(12);
  font-style: normal;
  font-weight: 400;
  width: toRem(154);
  line-height: normal;
  border-radius: toRem(8);
  border: 1px solid $ap-text-gray-1;
  background: #FFF;
  z-index: 1;
  top: toRem(-66);
  margin-top: 0px;

  &.right {
    right: 0px;
  }

  &.left {
    left: 0px;
  }

  .icon.close {
    width: 2rem;
    height: 1.5rem;
    margin-top: toRem(-2);
    background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_close_gray.svg) no-repeat;
  }
}

@include rwd('xs') {
  .psgBtn,
  .psgBtn:nth-last-child(2) {
    width: 100%;
    margin: 0 0 toRem(10) 0;
  }
  .innerWrapper .infoWrap {
    display: block;
  }
  .innerWrapper .infoWrap .info {
    width: 100%;
  }
  .innerWrapper .infoWrap .info:nth-child(2) {
    margin-top: toRem(10);
  }
  .seatBtn {
    width: toRem(24);
  }
  .seatRow .row span {
    font-size: toRem(16);
  }
  .seatMap .labelsWrapper .rowlbsWrapper {
    top: toRem(62);
  }
  .seatMap .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
    left: 69%;
  }
  .seatMap .labelsWrapper .clmnlbsWrapper span {
    width: toRem(24);
    font-size: toRem(20);
  }
  .seatMap .landmkTop .row .group.grLn {
    width: toRem(54);
  }
  .seatMap .landmkMid .toilWrapper:nth-child(2n+1) {
    width: toRem(86);
  }
  .seatMap .landmkMid .toilWrapper:nth-child(2) {
    width: toRem(88);
  }
  .seatMap.pre .landmkTop .row:nth-child(1) .galyWrapper {
    padding-left: toRem(20);
  }
  .seatMap.pre .landmkTop .row:nth-child(1) .toilWrapper,
  .seatMap.pre .landmkTop .row:nth-child(2) .toilWrapper {
    width: toRem(54);
  }
  .seatMap.pre .labelsWrapper .rowlbsWrapper {
    left: toRem(-15);
  }
  .seatMap.pre .labelsWrapper .rowlbsWrapper .lbWrapper + .lbWrapper {
    padding-left: toRem(25);
  }
  .seatMapWrapper.sct .row.bNn .toilWrapper,
  .seatMap .landmkBottom .row .sm,
  .seatMap.pre .landmkTop .row:nth-child(2) .galyWrapper,
  .landmkTop.bet .galyWrapper,
  .landmkTop.bet .toilWrapper {
    width: toRem(86);
  }

  /* 위탁 수하물 */
  .chkBgg {
    width: 100%;
  }
  .chkBgg .svcImgBox {
    width: toRem(70);
    height: toRem(80);
  }
  .svcImgBox img {
    width: toRem(50);
  }
  .svcInfo {
    width: calc(100% - #{toRem(80)});
  }
}

// Check bp...
@media only screen and (max-width: 349px) {
  .seatBtn {
    width: toRem(20);
  }
  .seatMap .landmkMid .toilWrapper:nth-child(2n+1) {
    width: toRem(66);
  }
  .seatMap .landmkMid .toilWrapper:nth-child(2) {
    width: toRem(78);
  }
  .seatMapWrapper.sct .row.bNn .toilWrapper,
  .seatMap .landmkBottom .row .sm,
  .seatMap.pre .landmkTop .row:nth-child(2) .galyWrapper,
  .landmkTop.bet .galyWrapper,
  .landmkTop.bet .toilWrapper {
    width: toRem(66);
  }

  /* 위탁 수하물 */
  .chkBggCon li {
    margin-bottom: toRem(40);
  }
  .chkBgg .svcImgBox {
    display: none;
  }
  .chkBgg .svcInfo {
    width: 100%;
    padding-left: 0;
  }
}


/****************
*** 결제 전 확인 ***
****************/


.cfmWrap .tickets {
  margin-top: toRem(60);
  margin-bottom: toRem(20);
}

.tickets {
  padding: toRem(30);
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(8);
}

.tickets .badge {
  padding: 0 toRem(6);
  font-size: toRem(16);
  font-weight: 500;
  line-height: 1.6em;
}

.tickets .age {
  font-weight: 700;
}

.tickets .flghtNo {
  padding-top: toRem(10);
}

.tickets .flghtNo .fltNo {
  font-size: toRem(18);
  font-weight: 600;
}

.tckInfoWrap {
  padding-top: toRem(30);
}

dl.info {
  margin-right: toRem(80);
}

dl.info dt {
  color: $ap-text-gray-1;
}

dl.info dd {
  padding-top: toRem(10);
  font-weight: 700;
}

dl.info dd em {
  margin: 0 toRem(5);
}

.dparCon dl.info:first-child {
  margin-right: 0;
}

/*
.dparCon .fltImg {
    margin: 0 toRem(20);
    margin-top: toRem(40);
}*/
.prcWrap {
  margin-top: toRem(34);
  color: $ap-point-orange;
}

.prcWrap > strong {
  font-size: toRem(22);
  font-weight: 700;
}

.agrWrap {
  padding-top: toRem(80);
}

.agrBtnWrap {
  padding-top: toRem(30);
  flex-wrap: wrap;
}

.agrBtnWrap > button {
  width: calc(25% - #{toRem(10)});
}

.agrChkWrap {
  padding-top: toRem(30);
}

.bPopWrap.agrPop .popScr {
  min-height: toRem(400);
}

.agrPop .subSmTit {
  margin-bottom: toRem(20);
}

.bPopWrap .grpWrap {
  margin-bottom: toRem(60);
}

.bPopWrap .grpWrap:last-child {
  margin-bottom: 0;
}

@include rwd('md') {

  .tickets {
    padding: toRem(20);
  }
  .tckInfoWrap {
    display: block;
  }
  .tckInfoWrap > .flex {
    display: block;
  }
  .tckInfoWrap > .flex > dl {
    margin-top: toRem(20);
  }
  .tckInfoWrap dl.info {
    margin-right: 0;
  }
  .tckInfoWrap dl.info dd {
    padding-top: 0;
  }
  .prcWrap {
    margin-top: toRem(20);
    padding-top: toRem(20);
    display: flex;
    justify-content: flex-end;
    border-top: toRem(1) solid $ap-line-gray;
  }
  .prcWrap > strong {
    font-size: toRem(18);
  }
  .agrBtnWrap > button {
    width: calc(50% - #{toRem(10)});
    margin-bottom: toRem(10);
    padding: 0 toRem(10) 0 toRem(15);
  }
}

@include rwd('sm') {
  .seatTop .tit {
    font-size: toRem(24);
  }
  .tickets .badge {
    font-size: toRem(14);
  }
  .tckInfoWrap {
    padding-top: toRem(30);
  }
}

@include rwd('xs') {
  .agrBtnWrap > button {
    width: 100%;
  }
  .dparCon .fltImg {
    width: toRem(68);
    background-position: center;
  }
}


/****************
****** 결제 ******
****************/


.contWrapper .left .inputWrap {
  padding: toRem(52) 0 toRem(60);
}

.contWrapper .left .inputWrap .slctCon {
  width: toRem(400);
}

.contWrapper .left .oneLineBox {
  width: 100%;
}

.caution-box {
  width: 50%;
  word-break: keep-all;
}

/* 예매>결제방법 flight_06pay*/
.fghPay .comTitle {
  position: relative;
}

.fghPay .sendButton {
  padding: 0 toRem(19);
}

.fghPay .badge.red {
  position: absolute;
  top: toRem(-32);
  left: 0;
  padding: 0 toRem(5);
  line-height: 1.6em;
}

.fghPay .slctBox > .slctCon {
  left: toRem(-100);
}

@include rwd('md') {
  .fghPay .contWrapper .right {
    margin-top: toRem(60);
  }
}

@include rwd('sm') {
  .fghPay {
    padding-bottom: toRem(300);
  }

  .fghPay .contWrapper .left {
    padding-bottom: toRem(32);
  }
  .fghPay .badge.red {
    top: toRem(25);
    line-height: 1.4em;
  }
  .fghPay .subTit sub.ftG {
    margin-top: toRem(20);
  }
  .fghPay .inputWrap .row {
    margin: 0;
    padding-top: toRem(20);
    display: flex;
    flex-direction: column;
    font-size: toRem(14);
  }
  .fghPay .inputWrap .row .label {
    width: 100%;
    word-break: keep-all;
  }
  .fghPay .inputWrap .row .value {
    padding: toRem(20) toRem(0);
  }
  .fghPay .inputWrap .row .value {
    padding: toRem(12) toRem(0);
  }
  .fghPay .slctBox button.val {
    margin-right: toRem(5);
    color: rgb(34, 34, 34);
    font-weight: 500;
  }
  .fghPay .slctBox > .slctCon {
    left: toRem(0);
  }


  /* 셀렉트창크기조절 */
  .fghPay .contWrapper .left .inputWrap .slctCon {
    width: toRem(240);
  }
  .fghPay .contWrapper .left .inputWrap {
    padding-top: toRem(20);
    padding-bottom: toRem(0);
  }
  .fghPay .contWrapper .right {
    width: 100%;
    margin-top: toRem(32);
    padding: 0;
  }
  .fghPay .payReceipt {
    width: 100%;
  }
  .fghPay .payReceipt .title {
    font-size: toRem(18);
  }
  .fghPay .payReceipt .expWrapper {
    padding-top: toRem(20);
  }
  .fghPay .payReceipt .expWrapper .itemsWrapper .row {
    padding-top: toRem(5);
  }
  .fghPay .fghPay {
    padding-bottom: toRem(300);
  }
  /* .fghPay .rounded .dftBtn {
	    width: 70%;
	    min-width:  toRem(170);
	    margin: 0 auto;
	    font-size: toRem(14);
	    justify-content: center;
	} */
  #popFghPayPrm {
    max-width: toRem(500);
  }
  #popFghPay .popTit,
  #popFghPayPrm .popTit {
    padding: toRem(50) toRem(25) 0;
  }
  #popFghPay .xBtnWrap {
    top: toRem(46);
  }
  #popFghPay .tbWrap .cpt {
    margin-bottom: toRem(20);
    font-size: toRem(13);
    text-align: inherit;
  }

  #popFghPayPrm .popScr.prM {
    padding-left: toRem(25);
    padding-right: toRem(25);
  }
  #popFghPayPrm .xBtnWrap {
    top: toRem(45);
  }
}

#pointUsageOption {
  height: toRem(48);
  padding: toRem(0);

  label {
    color: $ap-text-black;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
}


/****************
**** 마이페이지 ***
****************/


.prfCon .prfGroup {
  margin-bottom: toRem(60);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prfCon .prfGroup .profile {
  display: flex;
  align-items: center;
}

.prfCon .prfGroup .profile .prfLeft {
  width: toRem(66);
  height: toRem(90);
}

.prfCon .prfGroup .profile .prfRight {
  margin-left: toRem(30);
}

.prfCon .prfGroup .profile .prfRight .prfName {
  margin-bottom: toRem(5);
  font-size: toRem(26);
  font-weight: 700;
}

.prfCon .prfGroup .profile .prfRight .prfEmail {
  margin-bottom: toRem(15);
  color: $ap-text-gray-1;
}

.prfCon .prfGroup .linkWrap a {
  margin-right: toRem(50);
}

.prfCon .prfGroup .linkWrap a:last-child {
  margin-right: 0;
}

.prfCon .prfGroup .linkWrap a .title {
  color: $ap-text-gray-1;
  font-weight: 700;
}

.prfCon .prfGroup .linkWrap a .description {
  margin-top: toRem(10);
}

.prfCon .prfGroup .linkWrap a .description,
.prfCon .prfGroup .linkWrap a .description .iconWrap .txt {
  padding: 0;
  font-size: toRem(30);
  font-weight: 700;
}

.prfCon .prfGroup .linkWrap .coupon {
  padding-right: toRem(20);
}

.prfCon .prfGroup .linkWrap .coupon.on {
  position: relative;
}

.prfCon .prfGroup .linkWrap .coupon.on .title::after {
  content: "";
  width: toRem(5);
  height: toRem(5);
  margin-left: toRem(5);
  position: absolute;
  background: $ap-solid-orange;
  border-radius: 50%;
}

.prfCon .tabMenu .lineTb {
  margin-top: toRem(60);
}

.prfCon .tabMenu .lineTb thead td {
  line-height: toRem(24);
}

.prfCon .tabMenu .lineTb tbody tr {
  cursor: pointer;
}

.prfCon .tabMenu .lineTb tbody td {
  line-height: toRem(24);
}

.prfCon .tabMenu .lineTb tbody.none td {
  padding: toRem(100) 0;
  color: $ap-text-gray-2;
  font-weight: 400;
  text-align: center;
}

.prfCon .cautionWrap {
  margin-top: toRem(80);
}

.resignWrap {
  margin-top: toRem(100);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: toRem(14);
}

.resignWrap span {
  margin-right: toRem(10);
}

.tckCard .column:nth-child(1) {
  flex-basis: toRem(530);
}

.tckCard .column:nth-child(2) {
  flex-basis: 2 toRem(25);
}

.tckCard .column:nth-child(3) {
  flex-basis: 2 toRem(25);
}

.tckCard .column:nth-child(4) {
  flex-basis: 2 toRem(25);
}

@include rwd('sm') {
  .prfCon .prfGroup {
    display: block;
  }
  .prfCon .prfGroup .profile .prfRight .prfName {
    font-size: toRem(18);
  }
  .prfCon .linkWrap {
    margin-top: toRem(20);
  }
  .prfCon .prfGroup .linkWrap a .title {
    font-size: toRem(13);
  }
  .prfCon .prfGroup .linkWrap a .description,
  .prfCon .prfGroup .linkWrap a .description .iconWrap .txt {
    font-size: toRem(24);
  }
  .prfCon .rud .tabBtn {
    margin-bottom: toRem(20);
  }
  .prfCon .tabMenu .lineTb {
    margin-top: 0;
    margin-bottom: toRem(40);
  }


  /* 	thead 없애는 경우 하위 css속성 적용 */
  .prfCon .lineTb thead {
    display: none;
  }
  .prfCon .lineTb tbody {
    font-size: toRem(14);
    border-top: toRem(2) solid $ap-line-black;
  }
  .prfCon .cautionWrap {
    margin-top: toRem(40);
  }
}


@media only screen and (max-width: 374px) {
  .prfCon .prfGroup .linkWrap a {
    margin-right: 0;
  }
  .prfCon .linkWrap {
    display: flex;
    justify-content: inherit;
  }
}


/* 회원 등급 */
.mygrWrap .banWrap {
  padding: toRem(45);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(12);
}

.banWrap .sub {
  width: toRem(380);
  padding: toRem(20);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  font-weight: 300;
  text-indent: 0;
  background-color: $ap-white;
  border: toRem(1) solid $ap-line-black;
  border-radius: toRem(8);
  z-index: 1;
}

.banWrap .icon.info:hover .sub {
  display: block;
}

.mygrWrap .banWrap:nth-child(2) {
  margin-top: toRem(20);
  align-items: flex-end;
}

.mygrWrap .banWrap .left h1 {
  font-size: 3.2rem;
}

.mygrWrap .banWrap .left p {
  margin-top: toRem(50);
}

.mygrWrap .banWrap .left .myName {
  text-align: center;
}

.mygrWrap .banWrap .left .down {
  color: $ap-solid-orange;
}

.mygrWrap .banWrap .left .noChange {
  color: $ap-text-gray-1;
}

.mygrWrap .banWrap .right {
  flex-basis: 65%;
}

.mygrWrap .banWrap .right > div {
  display: flex;
  justify-content: space-between;
}

.mygrWrap .banWrap .right .grTop {
  margin-bottom: toRem(30);
}

.mygrWrap .banWrap .right .btCon {
  width: toRem(160);
}

.mygrWrap .banWrap .right .btCon:last-child {
  margin-right: 0;
}

.mygrWrap .banWrap .right .btCon p {
  font-size: toRem(14);
}

.mygrWrap .banWrap .right .btCon h1 {
  font-weight: 500;
}

.mygrWrap .banWrap .right .wholeScore {
  width: 100%;
  height: toRem(6);
  margin-bottom: toRem(10);
  background: $ap-text-gray-4;
  border-radius: toRem(3);
}

.mygrWrap .banWrap .right .wholeScore .currentScore {
  width: 0;
  height: 100%;
  background: $ap-solid-darkblue;
  border-radius: toRem(3);
}

.mygrWrap .banWrap .right .wholeScore .currentScore .nextScore {
  width: 0;
  height: 100%;
  background: $ap-solid-skyblue;
  border-radius: toRem(3);
}

.mygrWrap .banWrap .right .scoreTxt span {
  font-size: toRem(14);
}

.mygrWrap .banWrap .right .scoreTxt .zero {
  width: 37%;
  display: inline-block;
}

.mygrWrap .banWrap .right .scoreTxt .three {
  width: 63%;
  display: inline-block;
}

.mygrWrap .banWrap .right .scoreBubble {
  margin-bottom: toRem(20);
  position: relative;
}

.mygrWrap .banWrap .right .scoreBubble.haveScore .badgeArrow {
  background: $ap-point-orange;
  position: absolute;
  bottom: 0;
  left: 0;
}

.mygrWrap .banWrap .right .scoreBubble.finish .badgeArrow {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mygrWrap .gradeTb thead tr td {
  font-weight: 700;
}

.mygrWrap .gradeTb tbody tr td:first-child {
  font-weight: 700;
  text-align: left;
}

.mygrWrap.blue .gradeTb tr td:nth-child(2) {
  background: $ap-text-gray-5;
}

.mygrWrap.gold .gradeTb tr td:nth-child(3) {
  background: $ap-text-gray-5;
}

.mygrWrap.diamond .gradeTb tr td:nth-child(4) {
  background: $ap-text-gray-5;
}

.bPopWrap .grExp {
  margin-top: toRem(10);
  color: $ap-text-gray-1;
  font-size: toRem(14);
}

.bPopWrap .grPopCon {
  margin-top: toRem(30);
  padding: toRem(30) 0;
  border-top: toRem(2) solid $ap-line-black;
  border-bottom: toRem(1) solid $ap-text-gray-4;
}

.bPopWrap .subSmTit.scd {
  margin-top: toRem(60);
}

.mygrWrap .prioBag,
.mygrWrap .prioBoard {
  display: none;
}

.mygrWrap.diamond .prioBag,
.mygrWrap.diamond .prioBoard {
  display: block;
}

@include rwd('md') {
  .mygrWrap .banWrap {
    padding: toRem(30);
    flex-direction: column;
  }
  .banWrap .sub {
    top: 100%;
    left: auto;
    right: -50%
  }
  .mygrWrap .banWrap .left .down {
    text-align: center;
  }
  .mygrWrap .banWrap .left p {
    margin-top: toRem(10);
  }
  .mygrWrap .banWrap .left {
    margin-bottom: toRem(20);
  }
  .mygrWrap .banWrap .right .grTop {
    margin-bottom: toRem(20);
  }
  .mygrWrap .banWrap .right .btCon {
    width: calc(33.33% - #{toRem(10)});
    margin-right: 0;
    padding: toRem(10);
    text-align: center;
    border: toRem(1) solid $ap-line-gray-light;
    border-radius: toRem(10);
  }
  .mygrWrap .myGradeWrap {
    padding: toRem(30) 0;
    border: none;
  }
  .mygrWrap .banWrap:nth-child(2) {
    margin-top: toRem(30);
    align-items: center;
  }
  .mygrWrap .banWrap .right {
    width: 100%;
    flex-basis: auto;
  }
  .mygrWrap .banWrap .left h1 {
    text-align: center;
  }
  .mygrWrap tbody tr {
    height: toRem(80);
  }
  .mygrWrap tbody tr td {
    word-break: break-word;
  }
  .mygrWrap .cautionWrap {
    margin-top: 0;
  }
}

@include rwd('sm') {
  .banWrap .sub {
    width: toRem(200);
    right: auto;
    margin-left: -100%;
  }
}

@include rwd('xs') {
  .mygrWrap .banWrap .right .grTop,
  .mygrWrap .banWrap .right > div {
    margin-bottom: 0;
    flex-direction: column;
  }
  .mygrWrap .banWrap .right .btCon {
    width: 100%;
    margin-right: 0;
    margin-bottom: toRem(10);
  }
  .mygrWrap .banWrap .grdScorWrap > .scoreTxt {
    flex-direction: inherit;
  }
  .grdScorWrap {
    margin-top: toRem(20);
  }
  .mygrWrap .banWrap {
    padding: toRem(20);
  }
  .mygrWrap tbody tr {
    height: toRem(100);
  }
  .mygrWrap tbody tr td {
    word-break: normal;
  }


  /* 	팝업 */
  #popDia .popScr {
    max-height: inherit;
  }
  #popDia .grPopCon {
    margin-top: toRem(20);
    padding: toRem(10) 0;
  }
  #popDia .grPopCon .flex {
    margin-bottom: toRem(10);
    flex-direction: column;
    align-items: baseline;
  }
}


/* 나의 쿠폰, 포인트 */
.cpCont {
  display: flex;
  justify-content: space-between;
}

.cpTop .count {
  margin-top: toRem(10);
  font-size: toRem(26);
  font-weight: 700;
}

.cpTop .count .usName {
  margin-right: toRem(6);
}

.cpTop .count {
  height: toRem(50);
  display: flex;
  align-items: center;
}

.cpTop .expr {
  margin-top: toRem(36);
  font-size: toRem(16);
  font-weight: 500;
  color: $ap-solid-orange;
}

.cpTop .usable span {
  margin-right: toRem(10);
}

.pointWrap .btnWrap {
  display: flex;
}

.pointWrap .btnWrap .btn {
  margin-right: toRem(20);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pointWrap .btnWrap .btn:last-child {
  margin-right: 0;
}

.pointWrap .btnWrap .btn i {
  width: toRem(50);
  height: toRem(68);
  display: block;
  background-position: toRem(12) toRem(21);
  border-radius: toRem(28);
  border: toRem(1) solid $ap-text-gray-4;
}

.pointWrap .btnWrap .btn p {
  margin-top: toRem(10);
  font-size: toRem(14);
}

.pointWrap .popScr .contents .description {
  margin-top: toRem(10);
}

.pointCont {
  margin-top: toRem(120);
}

.popComplete .cplSv {
  margin-top: toRem(10);
  font-size: 1.8rem;
  font-weight: 700;
}

.popComplete .popRut {
  display: flex;
  justify-content: flex-start;
  color: $ap-text-gray-2;
}

.popComplete .popRut .popBar {
  width: toRem(1);
  height: toRem(20);
  background: $ap-line-gray;
}

.popComplete .popRut .cplmr {
  margin-right: toRem(10);
}

.popComplete .popRut .cplml {
  margin-left: toRem(10);
}

.popComplete .rcpConWrap {
  padding-bottom: 0;
}

.pointWrap .flex {
  display: inline-flex;
}

.pointWrap .flex .slctBox {
  margin: 0 toRem(10);
}

.pointWrap .flex .slctBox:first-child {
  margin-left: 0;
}

.pointWrap .flex .slctBox:last-child {
  margin-right: 0;
}

.mptInfo .flex .arrBar {
  width: toRem(1);
  height: toRem(20);
  background: $ap-line-gray;
}

.mptInfo .flex .mrTd {
  margin-right: toRem(10);
}

.mptInfo .flex .mlTd {
  margin-left: toRem(10);
}

@include rwd('sm') {
  .cpCont {
    display: block;
  }
  .cpTop .count {
    margin-top: 0;
    font-size: toRem(18);
  }
  .cpTop .expr {
    margin-top: toRem(20);
    font-size: toRem(14);
  }
  .popComplete .cplSv {
    font-size: toRem(14);
  }
  .pointWrap .btnWrap {
    margin-top: toRem(40);
    justify-content: space-around;
  }
  .pointWrap .btnWrap .btn {
    margin-right: 0;
  }
  .pointWrap .btnWrap .btn i {
    width: toRem(36);
    height: toRem(50);
    background-position: toRem(8) toRem(15);
    background-size: toRem(20);
  }
  .pointWrap .lineTb .ftK.taL {
    font-size: 0;
  }
  .mptInfo .pointTb .flex .arrBar {
    display: none;
  }
  .mptInfo .flex .mrTd {
    margin: 0;
  }
}

@media only screen and (max-width: 479px) {
  .popComplete .popRut {
    display: inline-grid;
  }
  .popComplete .popRut .popBar {
    display: none;
  }
  .popComplete .popRut .cplmr,
  .popComplete .popRut .cplml {
    margin: 0;
  }
}


/* 예약 조회 */
.icon.refresh {
  width: toRem(16);
  height: toRem(16);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_refresh_grey.svg') no-repeat;
  background-size: cover;
}

.loadFail {
  text-align: center;
  padding: toRem(56);

  .text {
    color: #808080;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    padding-bottom: toRem(16);
  }

  button {
    padding: toRem(11) toRem(32) toRem(11) toRem(40);
    border-radius: toRem(8);
    border: 1px solid #B2B2B2;
    display: flex;
    font-size: toRem(14);
    justify-content: center;
    align-items: center;
    gap: toRem(4);
    margin: 0 auto;
  }
}

.noRerv {
  font-size: toRem(16);
  font-weight: 700;
  color: #B2B2B2;
  line-height: 160%;
}

/* 예약 상세 */
.resDetail .detailInf {
  padding: toRem(32) 0;
  border-top: toRem(2) solid $ap-line-black;
  border-bottom: toRem(2) solid $ap-line-black;
}

.resDetail .detailInf .subSmTit {
  margin-bottom: toRem(30);
}

.resDetail .detailInf .infContent div {
  margin-right: toRem(80);
}

.resDetail .infContent h4 {
  color: $ap-text-gray-1;
  font-weight: 500;
  margin-bottom: toRem(2);
}

.resDetail .infContent p {
  font-weight: 700;
}

.resDetail .infContent .payment h4 {

}

.resDetail .infContent .payment p {
  color: $ap-point-orange;
}

.resDetail .resBtnWrap {
  padding-top: toRem(32);
}

.resDetail .resBtnWrap > button {
  width: 100%;
  margin-right: toRem(12);
}

.resDetail .resBtnWrap > button:last-child {
  margin-right: 0;
}

.resDetail .resBtnWrap .boxLnk {
  font-weight: 300;
  padding-left: 32px;
  padding-right: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.resDetail .resBtnWrap .icon {
  margin-right: toRem(10);
}

.resDetail .resBtnWrap .icon.change {
  width: toRem(20);
  height: toRem(20);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_reservation_change.svg') no-repeat;
  background-size: toRem(20);
}

.resDetail .resBtnWrap .icon.event {
  width: toRem(20);
  height: toRem(20);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_event.svg') no-repeat;
  background-size: toRem(20);
}

.resDetail .resBtnWrap .icon.cancel {
  width: toRem(20);
  height: toRem(20);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_reservation_cancel.svg') no-repeat;
  background-size: toRem(20);
}

.resDetail .badge {
  font-weight: 700;
  line-height: 1.4em;
  display: inline-block;
}

.resDetail .boardingWrap .subMdTit.flex {
  flex-wrap: wrap;
  gap: toRem(8);
}

.resDetail .boardingWrap .subMdTit .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: toRem(8);
}

.resDetail .icon.download {
  width: toRem(14);
  height: toRem(14);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_download.svg') no-repeat;
  background-size: toRem(14);
}

.resDetail .icon.email {
  width: toRem(14);
  height: toRem(14);
  background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_email.svg') no-repeat center center;
  background-size: toRem(14);
}

.boardingCon {
  margin-top: toRem(32);
  padding: toRem(30);
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(8);
}

.boardingCon > div {
  margin-bottom: toRem(30);
  padding-bottom: toRem(30);
  position: relative;
  border-bottom: toRem(1) dashed $ap-text-gray-4;
}

.boardingCon > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.boardingCon .top {
  margin-bottom: toRem(40);
}

.boardingCon .sendButton {
  position: absolute;
  top: toRem(-3);
}

.boardingCon .infContent > div {
  margin-right: toRem(80);
}

.boardingCon .infContent > div:first-child {
  flex-basis: 35%;
}

.boardingCon .infContent > div:nth-child(2) {
  flex-basis: 65%;
}

.resDetail .pasWrap .pasCon {
  margin-top: toRem(135);
}

.resDetail .pasWrap .pasCon:first-of-type {
  margin-top: toRem(32);
}

.resDetail .pasWrap .pasCon .pasName {
  padding: toRem(5) 0;
  border-bottom: toRem(1) solid $ap-line-black;
}

.resDetail .pasWrap .pasCon .pasInf {
  padding: toRem(32) 0;
  display: flex;
  border-bottom: toRem(1) solid $ap-text-gray-4;
  color: #222;
  flex-wrap: wrap;
  gap: 32px 12px;
}

.resDetail .pasWrap .pasCon .pasInf > div {
  flex: 1;

  @include rwd('lg') {
    min-width: 40%;
  }
  @include rwd('md') {
    min-width: 90%;
  }
}

.resDetail .pasWrap .pasCon .pasInf p {
  margin-top: toRem(20);
  color: $ap-text-gray-1;
  font-weight: 300;
}

.resDetail .pasWrap .pasCon .payCon {
  margin-top: toRem(25);
  display: flex;
}


.resDetail .pasWrap .pasCon .payCon {
  font-size: 20px;
  display: flex;
  align-items: center;

  h1 {
    font-size: 16px;
    margin: 0 30px 0 0;
    line-height: 1;
  }

  p {
    color: $ap-point-orange;
    font-size: 20px;
    font-weight: 700;
    text-decoration: underline;
  }
}

@include rwd('sm') {
  .resDetail .pasWrap .pasCon .pasInf.seatInf {
    flex-direction: column;
    gap: toRem(20);
  }
  .resDetail .pasWrap .pasCon .pasInf.seatInf p {
    margin-top: toRem(8);
  }
  .pasInf.seatInf .infContent.flex {
    justify-content: flex-start;
    gap: toRem(24);
  }
}


.ticketPop .popTit {
  display: flex;
  align-items: center;
}

.ticketPop .popTit .pTit {
  height: auto;
}

.ticketPop .popTit .pTit .br {
  display: block;
}

.ticketPop .popTit .pTit .exp {
  margin: toRem(10) 0;
  display: block;
  font-size: toRem(16);
  font-weight: 400;
}

.ticketPop .popTit .stewImg {
  width: toRem(110);
  height: toRem(110);
  margin-left: toRem(-20);
}

@include rwd('lg') {
  .boardingCon .infContent > div {
    margin-right: 0;
  }
  .boardingCon .infContent > div:nth-child(2) {
    padding-left: toRem(80);
  }
  .resDetail .detailInf .infContent div {
    margin-right: 0;
  }
  .resDetail .detailInf .infContent.flex {
    flex-wrap: wrap;
  }
  .resDetail .detailInf .infContent div {
    width: 50%;
    margin-right: 0;
    margin-bottom: toRem(10);
  }

  .resDetail .detailInf .infContent div:nth-child(2n) {
    text-align: right;
  }

  .resDetail .resBtnWrap {
    flex-direction: column;
  }
  .resDetail .resBtnWrap > button {
    margin-right: 0;
    margin-bottom: toRem(15);
  }
  .resDetail .resBtnWrap > button:last-child {
    margin-bottom: 0;
  }
}

@include rwd('md') {
  .ticketPop .popTit {
    display: block;
    text-align: center;
  }
  .ticketPop .popTit .pTit {
    padding-right: 0;
  }
  .boardingCon .infContent > div:nth-child(2) {
    margin-top: toRem(20);
    padding-left: 0;
  }
  .boardingLeave .infContent.flex,
  .boardingCome .infContent.flex {
    height: auto;
    padding-bottom: 0;
    display: block;
    overflow: hidden;
  }
  .boardingCon {
    padding: toRem(30) toRem(20) 0;
    overflow: hidden;
  }
  .boardingCon .infContent > div {
    margin-right: 0;
  }
  .boardingCon .boardingLeave .infContent.flex .flex,
  .boardingCon .boardingCome .infContent.flex .flex {
    margin-bottom: toRem(10);

    div:last-child {
      text-align: right;
    }
  }
  .boardingWrap .ticketWrap {
    flex-direction: column;
    align-items: baseline;
  }
  .boardingWrap .ticketWrap div {
    width: 100%;
    margin-bottom: toRem(10);
    display: flex;
    justify-content: space-between;
  }
  .resDetail .detailInf .subSmTit,
  .boardingCon .top {
    margin-bottom: toRem(20);
  }
  .boardingCon,
  .resDetail .pasWrap .pasCon:first-of-type {
    margin-top: toRem(20);
  }
  .ticketPop .popTit .pTit .exp {
    font-size: toRem(14);
  }
}


/* 예약 취소 */
.cancelConfirm .inputWrap .row {
  display: flex;
  flex-direction: row;
}

@include rwd('sm') {
  .cancelConfirm > .comStart,
  .cancelComplete > .comStart {
    padding-top: toRem(20);
  }
  .cancelConfirm .inputWrap .row {
    align-items: center;
  }
  .cancelConfirm .inputWrap .row .label,
  .cancelConfirm .inputWrap .row .value {
    width: auto;
  }
}


/****************
**** 로그인 ***
****************/


/* 공통 */
.lgBtnLabelS {
  font-weight: 700;
  user-select: none;
}


/* 라벨스타일01 (인풋만 존재) */
.lb01lgEm {
  margin-bottom: toRem(25);
  position: relative;
}

.lb01IW {
  width: 100%;
  min-height: toRem(40);
  position: relative;
  display: flex;
  align-items: center;
}

.lb01IW input {
  width: 100%;
  height: 100%;
  font-size: toRem(16);
}

.lb01IW.border input {
  border-bottom: solid toRem(1) $ap-line-gray;
  min-height: toRem(40);

  &:focus {
    border-bottom: solid toRem(1) $ap-line-black;
  }
}

.lb01IW.border input::placeholder {
  color: $ap-text-gray-2;
  font-size: 16px;
  font-weight: 400;
}


/* 라벨스타일02 (타이틀/ 인풋) */
.lbSty02 {
  display: flex;
  flex-direction: column;
}

.nameWrap .sln {
  width: 100%;
  /* margin-top: toRem(25); */
}

.lb02IW {
  width: 100%;
  min-height: toRem(40);
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: toRem(1) solid $ap-line-gray;
}

.lb02IW label {
  width: toRem(120);
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lb02IW label + input {
  width: calc(100% - #{toRem(120)});
}

.lbSty02 .iconBox {
  width: 20%;
  position: absolute;
  right: 0;
  top: 0;
}

.lb02IW .value.engWdt,
.lb02IW .dobJoin,
.lb02IW .txtNocut,
.lb02IW .iptLn {
  width: calc(100% - #{toRem(120)});
}

.value.sltLine {
  width: calc(100% - #{toRem(120)});
  display: flex;
  justify-content: space-between;
}

.value.sltLine .slctBox.w130 {
  display: inline-flex;
}

.value.sltLine .divideLine {
  width: toRem(1);
  height: toRem(18);
  margin: 0 toRem(10);
  display: block;
  background: $ap-text-gray-4;
}

/* +82셀렉트css */
.psBox {
  min-width: toRem(65);
  margin-right: toRem(11);
  padding: toRem(6) 0;
  flex-basis: auto;
  font-weight: 700;
}

.slctBox > .psCon {
  min-width: toRem(430);
}

.psCon > ul {
  width: 100%;
  top: toRem(65);
}

.psCon .seach {
  width: 90%;
  height: toRem(48);
  margin: 0 auto;
}

.psCon li {
  font-weight: 700;
  padding: toRem(15) toRem(24);
  list-style: none;
  cursor: pointer;
}

.psCon li:hover {
  background-color: $ap-text-gray-5;
}

.numberStrong {
  color: $ap-text-black;
  font-weight: 700;
}

.inline {
  display: contents !important;
}

@include rwd('sm') {
  .lb02IW .value.engWdt,
  .lb02IW .dobJoin,
  .lb02IW .txtNocut,
  .lb02IW .iptLn,
  .lb02IW .value.sltLine {
    width: 100%;
  }
  .numberStrong {
    font-size: toRem(14);
  }

  .lb01IW.border input {
    min-height: toRem(50);
  }
  .lb01IW.border input::placeholder {
    color: $ap-text-gray-2;
    font-size: 16px;
    font-weight: 400;
  }
}


/* search 창 컨포넌트 */
.searchBox {
  width: 90%;
  height: toRem(48);
  margin: 0 auto;
}

.searchBox input {
  color: $ap-text-black;
  width: 100%;
  height: 100%;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(4);
  z-index: 15;
  padding-inline-start: toRem(14);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_input_search.png) 98% toRem(10) no-repeat;
}

.slctListTitle {
  margin: 0 toRem(25) toRem(15);
  padding: toRem(15) 0;
  color: rgb(34, 34, 34);
  font-weight: 700;
  letter-spacing: toRem(-0.3);
  border-bottom: toRem(1) solid rgb(221, 221, 221);
}

.slctBox > .slctCon .searchArea:hover {
  background-color: $ap-white;
}

.filter li {
  font-weight: 700;
  padding: toRem(15) toRem(24) !important;
  list-style: none;
}


/* 휴대폰번호입력구역 */
.block {
  display: block;
}

.lineBox {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid toRem(1) $ap-text-gray-4;
}

.phoneLb {
  width: toRem(140);
  margin-bottom: toRem(4);
  letter-spacing: toRem(-0.28);
}

.NumberWrap {
  position: relative;
}

.phoneNumbArea {
  width: calc(100% - #{toRem(140)});
  display: flex;
  align-items: center;
}


/* 경고메세지 */
.lgMsg {
  position: absolute;
  bottom: toRem(-26);
  color: $ap-solid-red;
  z-index: 2;
}

.lgPw {
  margin-bottom: toRem(30);
}

.bluetext {
  position: absolute;
  bottom: toRem(-20);
  color: $ap-solid-skyblue;
}

.test {
  display: block;
  color: $ap-solid-red;
}

/* 하단 텍스트는 조건에 따라 클래스의 속성값을 display : block/none 으로 조정해주세요*/
.phoneChk {
  display: block;
}

.phoneOvs {
  display: block;
}

.sendMsg {
  display: block;
}


/* 로그인페이지 css */
.AuthWrapper {
  max-width: toRem(600) !important;
  margin: 3.75rem auto 0;
}

.lgTit {
  margin-bottom: toRem(120);
}

.comsTitle {
  font-size: toRem(26);
  font-weight: 700;
}

.login_form {
  width: 100%;
}

div.emailData > strong {
  font-weight: 700;
}

div.emailData > p {
  color: rgb(0, 147, 255);
  font-size: toRem(14);
  letter-spacing: toRem(-0.28);
}


/* sns 로그인 */
.snsloginBtn {
  margin-top: toRem(50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.snsloginBtn li {
  margin-right: toRem(10);
}

.snsloginBtn li:last-child {
  margin-right: 0;
}

.snsloginBtn .sns {
  width: toRem(40);
  height: toRem(54);
}

.snsloginBtn .snsB {
  width: toRem(68);
  height: toRem(68);
  border-radius: 50%;
}


/* 인풋 css */
.loginSubMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ap-text-gray-1;
  font-weight: 300;
}

.loginSubMenu a.ml {
  margin-left: toRem(28);
}


/* x버튼 css */
.xBtnWrapIP {
  height: inherit;
  position: absolute;
  top: toRem(13);
  right: 0;
  display: block;
}

.xBtnRound {
  display: flex;
  cursor: pointer;
}

.xBtnRound img {
  width: 100%;
}


/* 눈아이콘css */
.pawTglBtn {
  width: toRem(20);
  position: absolute;
  top: toRem(10);
  right: toRem(20);
  cursor: pointer;
}

.pawTglBtn img {
  width: 100%;
  vertical-align: baseline;
}

.eyesOn {
  display: none;
}

.pawTglBtn.abTop {
  top: toRem(20);
}


/* TaskButton css */
.dfTaskBtn {
  text-align: center;
}

.dfTaskBtn button {
  width: toRem(300);
}

/*.off { 사용안하면 삭제예정
    display: none;
}*/

@include rwd('sm') {
  /* 라벨스타일01 (인풋만 존재) */
  .lb01IW input {
    font-size: toRem(14);
  }
  /* 라벨스타일02 (타이틀/ 인풋) */
  .lb02IW label {
    width: 100%;
  }
  .lb02IW .value.engWdt {
    width: 100%;
  }
  .pawTglBtn.abTop {
    top: 0;
  }


  /* 로그인페이지 css */
  .lgTit {
    margin-bottom: toRem(60);
  }
  .comsTitle {
    font-size: toRem(18);
  }
  div.emailData > p {
    font-size: toRem(12);
  }
  .AuthWrapper {
    margin-top: 0;
  }

  /* 인풋 css */
  .loginSubMenu {
    font-size: toRem(14);
  }

  /* TaskButton css */
  .dfTaskBtn button {
    width: 100%;
    max-width: toRem(300);
  }
}


/****************
**** 이메일찾기 ***
****************/


.step1Wrap,
.step2Wrap,
.step3Wrap {
  max-width: toRem(600);
  margin: 0 auto;
}

.fet {
  margin-bottom: toRem(100);
}

.step1Tit, .step2Tit {
  margin-bottom: toRem(10);
}

.lgDes {
  color: $ap-text-gray-1;
  text-align: left;
}

.fef {
  width: 100%;
  /* margin-bottom:  toRem(60); */
}

.emFlexBox {
  width: 100%;
  position: relative;
  display: flex;
  align-items: baseline;
}

.pwInfotxt {
  margin-top: toRem(40);
  text-align: center;
}


/* 인증번호구역 */
.authNo {
  margin-top: toRem(25);
  position: relative;
  display: block;
}

.authNo > div {
  justify-content: space-between;
}

.authNo .phoneArea.acw {
  width: 100%;
}

.acw .inputWrapper {
  min-height: toRem(40);
  border-bottom: solid toRem(1) $ap-text-gray-4;
}

.timeGuide {
  margin-left: toRem(196);
  margin-top: toRem(6);
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  letter-spacing: toRem(-0.24);
}

.timeGuide .blue {
  font-size: 100%;
  color: $ap-point-blue;
}

.timeGuide sub:nth-child(2) {
  color: $ap-text-black;
}


/* 버튼 */
.goBack {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goBack p {
  font-weight: 700;
  letter-spacing: toRem(-0.26);
}

.goBack a {
  font-weight: 700;
  letter-spacing: toRem(-0.26);
  text-decoration: underline;
  cursor: pointer;
}

.step2Head {
  margin-bottom: toRem(60);
}

.step3Tit {
  padding-right: toRem(40);
  color: rgb(34, 34, 34);
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.4em;
}

.pwFlexBox {
  width: 100%;
  position: relative;
}

/* 패스워드 조건에 해당하지 않으면 아래의 클래스 적용하여 색 적용 */
sub.pwWng {
  color: $ap-solid-orange;
}

/* 패스워드 조건에 해당 시 아래의 클래스 적용하여 색 적용 */
sub.pwgd {
  color: $ap-solid-skyblue;
}

/* 입력 중 패스워드 조건에 해당하지 않으면 아래의 클래스 적용하여 색 적용 */
sub.pwWnging {
  color: $ap-text-gray-2;
}

/* 입력 중 패스워드 조건에 해당 시 아래의 클래스 적용하여 색 적용 */
sub.pwgding {
  color: $ap-text-black;
}

@include rwd('sm') {
  /* 라벨스타일02 (타이틀/ 인풋) */
  .lb02IW {
    padding-bottom: toRem(10);
    display: block;
  }
  .lb02IW label {
    margin-bottom: toRem(20);
  }
  .lb02IW label + input {
    width: 100%;
  }


  /* +82셀렉트css */
  .slctBox > .psCon {
    min-width: toRem(300);
  }


  /* 휴대폰번호입력구역 */
  .lineBox {
    display: block;
    height: auto;
  }
  .phoneNumbArea {
    width: 100%;
  }
}


/****************
**** 회원가입 ***
****************/


.gdRdoS {
  height: toRem(54);
  margin-right: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: solid toRem(1) $ap-line-gray;
  cursor: pointer;
}

.gdRdoS:first-of-type {
  border-radius: toRem(10) 0 0 toRem(10);
}

.gdRdoS:last-of-type {
  margin-left: toRem(-1);
  border-radius: 0 toRem(10) toRem(10) 0;
}

.gdRdoS label {
  color: $ap-text-gray-4;
  font-weight: 700;
  pointer-events: none;
}

.gdRdoS input {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
}

.gdRdoS:before {
  display: none;
}

.gdRdoS .selected {
  border-color: $ap-solid-orange;
  z-index: 1;
}

.gdRdoS.on {
  border-color: $ap-solid-orange;
  z-index: 1;
}

.gdRdoS.on label {
  color: $ap-solid-orange;
}

.agreeWrapList {
  display: flex;
  flex-direction: column;
}

.chkBoxLink {
  margin-bottom: toRem(10);
  display: flex;
  justify-content: space-between;
}

.scNum > li {
  list-style: none !important;
}

.scNum {
  padding-left: toRem(20) !important;
}

.scNum > li {
  padding-left: toRem(20) !important;
}

.txtIpCont {
  width: 100%;
  position: relative;
}

.brB {
  border-bottom: solid toRem(1) $ap-line-black;
}

.listStyleNone {
  list-style: none !important;
}

.toggleWrap {
  display: flex;
  align-items: center;
}

.toggleWrap .container {
  width: 100%;
  height: toRem(26);
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggleWrap .container > div {
  transition: all 0.2s;
}

.baseLine {
  width: toRem(42);
  height: toRem(4);
  position: absolute;
  top: toRem(12);
  display: block;
  border-radius: toRem(50);
  background: $ap-text-gray-2;
}

.toggleWrap.on .baseLine {
  background: $ap-solid-orange;
}

.toggleBtn {
  width: toRem(19);
  height: toRem(19);
  position: absolute;
  top: toRem(4);
  left: 0;
  background-color: $ap-white;
  border: solid toRem(1) $ap-line-gray;
  border-radius: toRem(25);
  box-shadow: toRem(2) toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.06);
}

.toggleWrap.on .toggleBtn {
  left: toRem(24);
}

.content {
  margin-left: toRem(52);
  font-weight: 500;
  font-size: toRem(12);
}

@include rwd('sm') {
  .content {
    font-size: toRem(12);
  }
}

.cookie {
  width: 100%;
  min-height: toRem(50);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: $ap-point-skyblue;
  z-index: 99;
}

.ckNoiceWrap {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.ckieLink {
  margin-left: toRem(20);
}

.ckNoiceWrap .cautionWrap {
  margin-top: 0;
}

.ckNoiceWrap .cautionWrap .title {
  margin-bottom: 0;
}

.taskButton.coockieBtn {
  width: toRem(100);
  height: toRem(35);
  margin-left: toRem(200);
  color: $ap-text-black;
  font-weight: 300;
  border: solid toRem(1) $ap-line-black;
  background: transparent;
}

.popTwoBtnW button {
  width: 45%;
}

/****************
**** 220525 항공권이 없습니다 부분 ***
****************/

.notiketInner {
  width: 60%;
  margin: toRem(100) auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notiketInner .noTikImg {
  height: toRem(250);
}

.notiketInner .noTikTit {
  margin: 0 auto;
  font-size: toRem(24);
  font-weight: 600;
}

@include rwd('lg') {
  .ckNoiceWrap {
    padding: 0 toRem(20);
    align-items: center;
  }
  .ckNoiceWrap .cautionWrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .cautionWrap .title,
  .cautionWrap .ftmt10 {
    font-size: toRem(14);
  }
  .taskButton.coockieBtn {
    margin-left: toRem(50);
  }
}

@include rwd('sm') {
  .ckNoiceWrap {
    padding: toRem(10) toRem(16);
    flex-direction: column;
  }
  .ckNoiceWrap .cautionWrap .title {
    margin-bottom: toRem(5);
  }
  .ckNoiceWrap .cautionWrap {
    margin-bottom: toRem(15);
  }
  .taskButton.coockieBtn {
    margin: 0 auto;
    font-size: toRem(14);
  }
}


@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein { /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein { /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


//IDEA...
.bounce {
  animation: bounce_frames 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: 20;
}

@keyframes bounce_frames {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 50px, 0);
  }
}

// 관리자에서 편집기에서 처리하여 인라인 스타일이나 권한이 없어 강제 처리한 부분...
// 메인 > 푸터 > 개인정보 처리방침 > .subSmTit
.psInfo .subSmTit {
  font-size: toRem(20) !important;
}


.noFlightText {
  font-size: toRem(16);
  font-weight: 700;
  line-height: 160%;
  font-size: toRem(16);
  color: #222222;
  padding-top: toRem(100);
  display: flex;
  justify-content: center;
}

/* 예약 취소 인증 팝업 */
#verificationPhonePopup {
  input {
    width: 100%;
    font-size: toRem(16);
    font-style: normal;
    font-weight: 700;
    line-height: toRem(24);
    color: rgba(0, 0, 0, 0.20);
  }

  .popTit {
    padding-bottom: toRem(28);
  }

  .popDescription {
    padding-top: toRem(12);
    color: #6D7788;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 600;
    line-height: toRem(22);
  }

  .description {
    padding: toRem(12) 0;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(22);
    color: #9AA2B1;
  }

  .popScr {
    padding: 0 toRem(48) toRem(40) toRem(36);
  }
}