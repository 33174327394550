@charset "UTF-8";
@import "../ap_preaset";

/* gnb */
.gnb {
  height: 64px;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 24px;
  * {
    line-height: 1.5rem;
    @include rwd('lg') {
      line-height: 1.6;
    }
  }

  @include rwd('lg') {
    gap: 20px;
  }

  .logo {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .logo a {
    max-width: 134px;
    width: 100%;
    height: auto;
    @include rwd("lg") {
      max-width: 125.62px;
      width: 100%;
      height: auto;
    }
  }

  .logo a svg {
   width: 100%;
  }

  .iconLang {
    width: 24px;
    height: 24px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_language.svg') no-repeat center;
    background-size: cover;
  }
  .iconKorea {
    width: 20px;
    height: 20px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/korea.png') no-repeat center;
    background-size: cover;
  }
  .iconEnglish {
    width: 20px;
    height: 20px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/english.png') no-repeat center;
    background-size: cover;
  }
  .iconJapan {
    width: 20px;
    height: 20px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/japan.png') no-repeat center;
    background-size: cover;
  }
  .iconProfile {
    width: 32px;
    height: 32px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/profile-img.png') no-repeat center;
    background-size: cover;
    border-radius: 50%;
    flex: none;
  }
  .iconArrowDropDown {
    width: 16px;
    height: 16px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_drop_down.svg') no-repeat center;
    background-size: cover;
  }

  .main {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    height: 100%;

    > li {
      height: 44px;
      display: flex;
      align-items: center;

      > .label {
        padding: 0 16px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        color: $ap-text-link-gnb;
        white-space: nowrap;

        @include rwd('lg') {
          font-size: 14px;
          padding: 0 12px;
        }
      }

      &:hover {
        background: #0000000D;
        border-radius: 12px;
        .subWrap {
          display: block;
        }
      }

      &.current {
        > .label{
          color:$ap-solid-orange;
        }
      }
    }
  }

  // 서브메뉴
  .subWrap {
    position: absolute;
    top: 49px;
    display: none;
    z-index: 100;
    padding-top: 10px;
  }

  .sub {
    border-radius: 16px;
    border: 1px solid var(--Outline-Neutral-outline3, #E6EAF0);
    background: var(--Container-neutral-Container1, #FFF);
    min-width: 220px;
    width: auto;

    /* Shadow/medium */
    box-shadow: 0 8px 12px 0 rgba(51, 61, 75, 0.06);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
    gap: 4px;
    > li {
      border-radius: var(--Radius-Radius-Medium, 10px);
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background: #0000000D;
      }

      > a {
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: stretch;
        gap: 8px;
        color: $ap-text-link-gnb;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 150% */
        white-space: nowrap;
        user-select: none;

        @include rwd('lg') {
          font-size: 14px;
        }
      }
    }
  }

  /* subWrap.service - 서비스 안내 메뉴 예외처리 클래스 */
  .subWrap.service {
    left: 20px;
    @include rwd('lg') {
      left: 90px;
      //right: 0;
    }
  }

  .subWrap {
    .subGroup {
      border-radius: 16px;
      border: 1px solid var(--Outline-Neutral-outline3, #E6EAF0);
      background: var(--Container-neutral-Container1, #FFF);

      /* Shadow/medium */
      box-shadow: 0 8px 12px 0 rgba(51, 61, 75, 0.06);

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      .sub {
        border: 0;
        box-shadow: none;
        width: 250px;
        @include rwd('lg') {
          min-width: auto;
          width: 204px;
        }
        padding: 0;

        > li {
          padding: 0;
          &:hover {
            background: none;
          }
        }
      }
    }

    .title {
      display: flex;
      padding: 6px 10px;
      margin-bottom: 10px;
      cursor: default;
      user-select: none;
      .label {
        color: var(--On-View-onView4, #9AA2B1);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      li {
        border-radius: var(--Radius-Radius-Medium, 10px);
        transition: all 0.3s;

        &:hover {
          background: #0000000D;
        }

        > a {
          display: flex;
          padding: 8px 10px;
          align-items: center;
          justify-content: stretch;
          gap: 8px;
          color: $ap-text-link-gnb;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 150% */
          user-select: none;

          @include rwd('lg') {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.sCnd {
  min-width: 200px;
  width: auto;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 59px;
  display: none;
  background-color: #fff;
  border: 1px solid #E6EAF0;
  border-radius: 16px;
  z-index: 1;
  /* Shadow/medium */
  box-shadow: 0 8px 12px 0 rgba(51, 61, 75, 0.06);

  &.right {
    right: 0;
  }

  > li {
    border-radius: var(--Radius-Radius-Medium, 10px);
    transition: all 0.3s;

    &:hover {
      background: var(--Fill-Container-neutral-Container3, #F4F6F8);
    }

    a {
      padding: 8px 10px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      gap: 8px;
      color: $ap-text-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      white-space: nowrap;

      span {
        flex: 1;
      }
    }
  }

  &[data-locale="ko"] > li.ko > a,
  &[data-locale="en"] > li.en > a,
  &[data-locale="ja"] > li.ja > a {
    &:after {
      content: "";
      display: block;
      width : 16px;
      height : 16px;
      background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-selected.svg') no-repeat center;
      background-size: cover;
    }
  }

  &.user-menu {
    min-width: 248px;
  }

  .profile-info {
    margin-bottom: 10px;
    padding: 20px 20px 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .iconAvatar{
      width: 48px;
      height: 66px;
      background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/profile-img.png') no-repeat center;
      background-size: cover;
      margin-bottom: 8px;
    }
    .info-username{
      color: var(--On-View-onView2, #333D4B);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
      white-space: nowrap;
    }
    .info-email{
      color: var(--On-View-onView2, #333D4B);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      word-break: break-all;
      text-align: center;
    }
  }
}

.btnHeader {
  .hdMain {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > li {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      strong {
        color: $ap-solid-orange;
        font-weight: 700;
      }
      &:hover .sCnd {
        display: block;
      }
    }
    > li + li {
      margin-left: 4px;
    }

    p {
      color: $ap-text-black;
      font-size: toRem(14);
      //font-weight: 300;
    }

    .button {
      display: flex;
      height: 42px;
      padding: 0 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 12px;
      border: 1px solid var(--Outline-Neutral-outline3, #E6EAF0);
      background: var(--Container-neutral-Container1, #FFF);
      white-space: nowrap;

      color: var(--On-View-onView2, #333D4B);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */

      &:hover {
        background: var(--Container-hover-hoverLight, rgba(0, 0, 0, 0.05));
      }

      &.borderless {
        border: 0;
        padding: 0 9px;
      }


      + .button {
        margin-left: 4px;
      }
    }

    .profile-box {
      display: flex;
      align-items: center;
      justify-content: stretch;
      overflow: hidden;
      padding: 6px var(--Radius-Radius-Large, 12px);
      gap: 8px;
      color: var(--On-View-onView1, #171A22);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      border-radius: 12px;
      max-width: 164px;
      cursor: pointer;
      &:hover {
        background: var(--Container-hover-hoverLight, rgba(0, 0, 0, 0.05));
      }

      > span {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

/* gnb.mb */
.gnb.mb {
  height: 44px;
  display: none;
  justify-content: space-between;
  font-size: 14px;

  .rightMenu {
    display: flex;
    align-items: center;
    gap: 4px;

    .login-btn {
      padding: 10px 14px;
      cursor: pointer;
      line-height: 22px; /* 157.143% */
      border-radius: 12px;
      font-weight: 500;
      &:hover {
        background: var(--Container-hover-hoverLight, rgba(0, 0, 0, 0.05));
      }
    }

    .profile-box {
      display: flex;
      padding: 6px;
      align-items: center;
      gap: 8px;
      color: var(--On-View-onView1, #171A22);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      border-radius: 12px;
      &:hover {
        background: var(--Container-hover-hoverLight, rgba(0, 0, 0, 0.05));
      }
      .iconProfile {
        flex: none;
        width: 32px;
        height: 32px;
        background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/profile-img.png') no-repeat center;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .btn {
      padding: 9px;
      border-radius: 12px;
      svg {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background: var(--Container-hover-hoverLight, rgba(0, 0, 0, 0.05));
      }
      &.open {
        position: relative;
      }
      &.close {
        position: absolute;
        top: 6px;
        right: 16px;
        z-index: 101;
      }
    }

    &.fix {
      .btn.open {
        display: none;
      }
    }
  }

  .menuWrap {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    overflow-y: auto;
    background: #fff;
    z-index: 100;
    &.on {
      display: block;
    }

    .btnHeaderMb {
      position: sticky;
      top: 0;
      padding: 6px 16px 8px;
      border-bottom: 8px solid var(--Greyscale-Grey80, #F7F7F7);
      background: #fff;

      .header-tools {
        display: inline-flex;
        align-items: center;
        gap: 16px;
        .button {
          display: inline-flex;
          height: 32px;
          padding: 5px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 10px;
          border: 1px solid var(--Outline-Neutral-outline3, #E6EAF0);
          background: var(--Container-neutral-Container1, #FFF);

          .iconKorea {width: 16px;height: 16px;}
          .iconEnglish {width: 16px;height: 16px;}
          .iconJapan {width: 16px;height: 16px;}
        }
        .link {
          color: var(--On-View-onView3, #6D7788);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }

      &.on .subMb {
        display: block;
      }

      .subMb > li a {
        padding: toRem(5) 0;
        display: block;
        color: $ap-text-black;
        white-space: nowrap;
      }


      .quick-menu {
        padding: 16px 0 14px;

        display: flex;
        align-items: stretch;
        justify-content: stretch;
        gap: 8px;

        a {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          padding: 8px 0;

          &:hover {
            border-radius: 8px;
            background: var(--Frame-GreyScale-Grey20, #F4F6F8);
          }
        }
        .icoFindTrip {
          width: 24px; height: 24px;
          background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_find_trip.svg") no-repeat center center;
          background-size: cover;
        }
        .icoEvent {
          width: 24px; height: 24px;
          background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_event_mm.svg") no-repeat center center;
          background-size: cover;
        }
        .icoNotice {
          width: 24px; height: 24px;
          background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_notice_mm.svg") no-repeat center center;
          background-size: cover;
        }
        .icoQuestion {
          width: 24px; height: 24px;
          background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_question_mm.svg") no-repeat center center;
          background-size: cover;
        }
      }
    }

    .menus {
      overflow: hidden;
      background-color: $ap-white;
      padding: 32px 16px;

      .list {
        dt {
          color: var(--Text-GreyScale-Grey100, #171A22);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          padding: 14px 0;

          background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_down.svg) no-repeat 100% center;
          background-size: 20px;
          cursor: pointer;
        }

        dd {
          display: none;
          cursor: pointer;
        }
        &.open {
          padding-bottom: 24px;
          dt {
            background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_up.svg) no-repeat 100% center;
            background-size: 20px;
          }
          dd {
            display: block;
            a {
              padding: 12px 0;
              display: block;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              color: #333D4B;

              &:hover {
                color: #6d7788;
              }
            }
            p {

            }
          }
        }

      }
    }
  }
}

/* mb 언어선택 */

.mbLangList {
  flex-direction: column;
}

.mbLangList > div > p {
  margin-right: toRem(10);
}

ul.subMb {
  min-width: toRem(130);
  padding: toRem(20);
  position: absolute;
  top: toRem(35);
  right: 0;
  display: none;
  background-color: $ap-white;
  border: toRem(1) solid $ap-line-black;
  border-radius: toRem(8);
  z-index: 1;
}

@include rwd('md') {
  .header,
  .gnb.pc {
    display: none;
  }
  .gnb.mb {
    display: flex;
  }
  .gnb .logo {
    margin: 0;
    padding: 0;
    a {
      height: 13px;
      background-size: contain;

      svg {
        max-width: 108px;
        width: 100%;
        height: auto;
      }
    }
  }
  .gnb .subWrap.service .list li .label {
    font-weight: 400;
  }
  .gnb.mb .menuWrap .menus .notice dt,
  .gnb.mb .menuWrap .menus .list dt {
    font-weight: 400;
  }
}

@include rwd('sm') {}

@media only screen and (max-width: 359px) {
  .btnHeaderMb {
    right: toRem(65);
  }
}

#popLangSelector {
  .lang-selector {
    padding: 16px 0;

    &[data-locale="ko"] > li.ko > a,
    &[data-locale="en"] > li.en > a,
    &[data-locale="ja"] > li.ja > a {
      color: $ap-solid-orange;
      &:after {
        content: "";
        display: block;
        width : 16px;
        height : 16px;
        background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-selected-orange.svg') no-repeat center;
        background-size: cover;
      }
    }
  }
  .iconKorea {
    width: 20px;
    height: 20px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/korea.png') no-repeat center;
    background-size: cover;
  }
  .iconEnglish {
    width: 20px;
    height: 20px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/english.png') no-repeat center;
    background-size: cover;
  }
  .iconJapan {
    width: 20px;
    height: 20px;
    background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/japan.png') no-repeat center;
    background-size: cover;
  }

  li {

    border-radius: var(--Radius-Radius-Medium, 10px);
    transition: all 0.3s;

    &:hover {
      background: var(--Fill-Container-neutral-Container3, #F4F6F8);
    }

    a {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      gap: 8px;
      color: $ap-text-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      white-space: nowrap;

      span {
        flex: 1;
      }
    }
  }
}
